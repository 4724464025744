import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import PModal from 'components/PModal'
import Table from 'components/system/Table'
import addDays from 'date-fns/add_days'
import addMonths from 'date-fns/add_months'
import format from 'date-fns/format'
import Button from '@material-ui/core/Button'
import {
  cancelStripeSub,
  customerInfo,
  resumeStripeSub,
  getInvoice
} from 'api/stripe'
import Loader from 'components/RctPageLoader'
import PropTypes from 'prop-types'
import {PricePlanDataByPlanId} from './PricePlan/PricePlanData'

const fontColorByStatus = {
  pending: 'support.yellow.5',
  active: 'primary.5',
  cancelled: 'support.red.5',
  completed: 'support.green.5'
}
// TODO: Hide next payment cycle record if current date > nextStartDate
// TODO: Handle multiple subs (eg, list of sub history)
// TODO: Handle user without payment history
// TODO: Dont show nextsub if current one is expired
// TODO: User can not subscribe if their currently is active

const Subscription = ({authUser, open, closeHandler}) => {
  const stripeCustomerId = authUser.stripe
    ? authUser.stripe[0].stripeCustomerId
    : null
  const subValidity = authUser.stripe
    ? authUser.stripe.reduce((acc, {stripeSubId, subValid}) => {
        acc[stripeSubId] = subValid
        return acc
      }, {})
    : {}
  const [subscriptions, setSubscriptions] = useState(null)
  const [invoiceData, setInvoiceData] = useState(null)

  useEffect(() => {
    let mounted = true
    if (stripeCustomerId) {
      // customerInfo(stripeCustomerId)
      //   .then(({data}) => {
      //     if (mounted) {
      //       setSubscriptions(data.subscriptions.data)
      //     }
      //   })
      //   .catch(e => {
      //     console.log(e)
      //   })
      const getStripeInfo = async () => {
        try {
          const {data: invoiceDataset} = await getInvoice(stripeCustomerId)
          const {data: customerData} = await customerInfo(stripeCustomerId)
          const reducedInvoiceData = invoiceDataset.data.reduce(
            (acc, value) => {
              acc[value.subscription] = value
              return acc
            },
            {}
          )
          if (mounted) {
            setInvoiceData(reducedInvoiceData)
            setSubscriptions(customerData.subscriptions.data)
          }
        } catch (e) {
          // console.log('caught error:', e)
        }
      }
      getStripeInfo()
    }
    return () => {
      mounted = false
    }
  }, [stripeCustomerId])

  const cancelSub = async () => {
    const {data} = await cancelStripeSub(subscriptions[0].id)
    // eslint-disable-next-line no-unused-vars
    const [current, ...rest] = subscriptions
    setSubscriptions([data, ...rest])
  }

  const resumeSub = async () => {
    const {data} = await resumeStripeSub(subscriptions[0].id)
    // eslint-disable-next-line no-unused-vars
    const [current, ...rest] = subscriptions
    setSubscriptions([data, ...rest])
  }

  const getInvoiceLink = subId => {
    const link = invoiceData[subId].invoice_pdf
    return link
  }

  const createSubData = ({plan, current_period_start, id}) => {
    const {type, amount: price} = PricePlanDataByPlanId[plan.id]
    const stripeStartDate = current_period_start * 1000
    const stripeEndDate = addMonths(stripeStartDate, 3)
    return {
      id,
      type,
      price,
      status: subValidity[id] ? 'active' : 'completed',
      startDate: stripeStartDate,
      endDate: stripeEndDate,
      option: 'Invoice',
      handler: () => {},
      link: getInvoiceLink(id)
    }
  }

  const createNextSub = ({
    plan,
    current_period_start,
    cancel_at_period_end: cancelledAtEnd
  }) => {
    const {type, amount: price} = PricePlanDataByPlanId[plan.id]
    const stripeEndDate = addMonths(current_period_start * 1000, 3)
    const nextStartDate = addDays(stripeEndDate, 1)
    return {
      id: 0,
      type,
      price,
      status: cancelledAtEnd ? 'cancelled' : 'pending',
      startDate: nextStartDate,
      endDate: addMonths(nextStartDate, 3),
      option: cancelledAtEnd ? 'Resume' : 'Cancel',
      handler: cancelledAtEnd ? resumeSub : cancelSub,
      link: null
    }
  }

  const getTableData = () => [
    createNextSub(subscriptions[0]),
    ...subscriptions.map(sub => createSubData(sub))
  ]

  const renderTableBody = () => {
    if (!stripeCustomerId) {
      return (
        <tr>
          <td colSpan="6" style={{paddingTop: 40, textAlign: 'center'}}>
            No Records
          </td>
        </tr>
      )
    }
    if (stripeCustomerId && (!subscriptions || !invoiceData)) {
      return (
        <tr>
          <td colSpan="6" style={{paddingTop: 40, textAlign: 'center'}}>
            No Records
          </td>
        </tr>
      )
    }
    if (!subscriptions || !invoiceData) {
      return (
        <tr>
          <td colSpan="6" style={{paddingTop: 40}}>
            <Loader />
          </td>
        </tr>
      )
    }

    return getTableData().map(
      ({
        id,
        type,
        price,
        status,
        startDate,
        endDate,
        option,
        handler,
        link
      }) => (
        <tr key={id}>
          <Cell>{type}</Cell>
          <Cell number>{`$${price}`}</Cell>
          <Cell highlight status={status}>
            {status}
          </Cell>
          <Cell>{format(startDate, 'DD-MM-YYYY')}</Cell>
          <Cell>{format(endDate, 'DD-MM-YYYY')}</Cell>
          <Cell textAlign="center">
            {link ? (
              <Button
                size="medium"
                color="primary"
                aria-label="invoice"
                onClick={() => {
                  window.open(link, '_blank')
                }}
              >
                {option}
              </Button>
            ) : (
              <Button
                variant={option === 'Resume' ? 'contained' : 'outlined'}
                size="medium"
                color={option === 'Resume' ? 'primary' : 'default'}
                aria-label={option}
                onClick={handler}
              >
                {option}
              </Button>
            )}
          </Cell>
        </tr>
      )
    )
  }

  return (
    <PModal heading="Subscription" open={open} toggleHandler={closeHandler}>
      <PTable>
        <thead>
          <tr>
            <Head>Plan</Head>
            <Head number>Amount (monthly)</Head>
            <Head>Status</Head>
            <Head>Start Date</Head>
            <Head>Period end</Head>
            <Head textAlign="center">Options</Head>
          </tr>
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </PTable>
    </PModal>
  )
}

Subscription.propTypes = {
  authUser: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired
}

const PTable = ({children}) => (
  <Table width={1} mb={3}>
    {children}
  </Table>
)

PTable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

const Cell = ({children, number, highlight, status, textAlign}) => (
  <Table.Cell
    textAlign={number ? 'right' : textAlign}
    p={2}
    pr={number ? 4 : 0}
    fontSize={2}
    fontWeight={highlight ? 5 : 3}
    color={fontColorByStatus[status]}
    textStyle="upper"
    letterSpacing="wide"
  >
    {children}
  </Table.Cell>
)

Cell.defaultProps = {
  number: false,
  textAlign: 'left',
  highlight: false,
  status: ''
}

Cell.propTypes = {
  number: PropTypes.bool,
  textAlign: PropTypes.string,
  highlight: PropTypes.bool,
  status: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

const Head = ({children, number, textAlign}) => (
  <Table.Head
    width={1 / 6}
    textAlign={number ? 'right' : textAlign}
    p={2}
    pr={number ? 4 : 0}
    fontSize={2}
  >
    {children}
  </Table.Head>
)

Head.defaultProps = {
  number: false,
  textAlign: 'left'
}

Head.propTypes = {
  number: PropTypes.bool,
  textAlign: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

const mapStatesToProps = ({authUser}) => ({authUser})

export default connect(
  mapStatesToProps,
  null
)(Subscription)
