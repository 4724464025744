import theme from '../../settings/theme'

const PricePlanData = {
  starter: {
    features: [
      'Unlimited access to the ProjectMe platform',
      'Self-awareness – know what you need & prefer',
      'Suggestions on Avatars that you are most likely to love',
      'Compare your profile to ~960 Avatars',
      'Know the earning potential of each Avatar',
      'Make your focused Avatar a reality with an action plan',
      'Get suggestions on courses you should study',
      'Audit online reputable University courses for free'
    ],

    // planId: 'plan_EULXVFjNAFYaVw',
    prices: [
      {
        period: 1,
        amount: 15.99,
        planId: 'plan_EqUI6ANT4QvvMY',
        total: 15.99,
        mode: 'live'
      },
      {
        period: 12,
        amount: 9.99,
        planId: 'plan_EqpFu6i0zzHgyM',
        total: 119.88,
        mode: 'live'
      }
      // {
      //   period: 1,
      //   amount: 15.99,
      //   planId: 'plan_EmQqeoAs2UgfaA',
      //   total: 15.99,
      //   mode: 'test',
      // },
      // {
      //   period: 12,
      //   amount: 9.99,
      //   planId: 'plan_FCw74jetLjreKY',
      //   total: 119.88,
      //   mode: 'test',
      // },
    ],
    color: theme.colors.support.yellow[0],
    bg: theme.colors.support.yellow[5],
    planTitle: 'Student'
  },
  experienced: {
    features: [
      'Unlimited access to the ProjectMe platform',
      'Self-awareness – know what you need & prefer',
      'Suggestions on Avatars that you are most likely to love',
      'Compare your profile to ~960 Avatars',
      'Know the earning potential of each Avatar',
      'Make your focused Avatar a reality with an action plan',
      'Get suggestions on courses you should study',
      'Audit online reputable University courses for free',
      'Compare what you are good at to Avatars'
    ],
    // planId: 'plan_EULY2ubUClXkwa',
    prices: [
      {
        period: 1,
        amount: 29.99,
        planId: 'plan_EqUJsyVuGd7ujB',
        total: 29.99,
        mode: 'live'
      },
      {
        period: 12,
        amount: 19.99,
        planId: 'plan_EqpGS1TVyaNZyS',
        total: 239.88,
        mode: 'live'
      }
      // {
      //   period: 1,
      //   amount: 29.99,
      //   planId: 'plan_EmQrrM8YX98lkL',
      //   total: 29.99,
      //   mode: 'test',
      // },
      // {
      //   period: 12,
      //   amount: 19.99,
      //   planId: 'plan_FCw6nvRqWUjW96',
      //   total: 239.88,
      //   mode: 'test',
      // },
    ],
    color: theme.colors.support.green[0],
    bg: theme.colors.support.green[5],
    planTitle: 'Individual'
  },
  team: {
    features: [
      'Everything in Individual Plan',
      'Compare what you are good at to Avatars',
      'Structure incentives based on collective values of the team',
      'Enable employee driven growth conversations',
      'Inform Change Management approach based on collective Styles',
      'Identify talent gaps',
      'Free inclusive and extensive learning opportunities',
      'Enable more effective mentoring pairing'
    ],
    planId: null,
    prices: [{period: null, amount: 'P.O.A'}],
    color: theme.colors.support.red[0],
    bg: theme.colors.support.red[5],
    planTitle: 'Team'
  }
}

const getPricePlanById = () => {
  const types = Object.keys(PricePlanData)
  const pricePlans = {}
  for (let i = 0; i < types.length; i += 1) {
    const type = types[i]
    // const {planId, prices, features} = PricePlanData[type]
    // pricePlans[planId] = {planId, prices, features, type}
    const {prices, features} = PricePlanData[type]
    prices.forEach(item => {
      pricePlans[item.planId] = {...item, type, features}
    })
  }
  return pricePlans
}

export const PricePlanDataByPlanId = getPricePlanById()

export default PricePlanData
