import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {
  getLandingPageData,
  allTypeAvatars,
  getPlaygroundData,
  avatarDetail,
  getUserInfoOverall
} from 'api'
import {
  REFRESH_USER_FOCUSPLAN,
  GET_USER_RESULT,
  GET_DATA_FOR_CURRENT_AVATAR,
  REFRESH_USER_INFO
} from 'actions/types'
import {
  updateUserFocusPlan,
  getUserResultSuccess,
  getCurrentAvatarDataSuccess,
  updateUserInfo
} from 'actions'

// Referesh user info
const refreshUserInfoRequest = async email => {
  const {data} = await getUserInfoOverall(email)
  const {surveys} = data.userInfo[0]
  return {surveys}
}

function* refreshUserInfo({payload}) {
  const {email} = payload

  try {
    const result = yield call(refreshUserInfoRequest, email)
    yield put(updateUserInfo(result))
  } catch (error) {
    // TODO: handle error
  }
}

export function* refreshUserInfoSaga() {
  yield takeEvery(REFRESH_USER_INFO, refreshUserInfo)
}

// Refresh landingpage data
const refreshFocusPlanRequest = async userId =>
  getLandingPageData(userId).then(response => {
    const {userinfo, ...rest} = response
    return {info: {...rest}}
  })

function* refreshFocusPlan({payload}) {
  const {userId} = payload

  try {
    const result = yield call(refreshFocusPlanRequest, userId)
    yield put(updateUserFocusPlan(result))
  } catch (error) {
    // TODO: handle error
  }
}

export function* refreshUserFocusPlan() {
  yield takeEvery(REFRESH_USER_FOCUSPLAN, refreshFocusPlan)
}

/**
 * User Result
 */
const getUserResultRequest = async userId =>
  allTypeAvatars(userId)
    .then(({data}) => data)
    .catch(err => err)

function* getUserResult({payload}) {
  const {userId} = payload
  try {
    const result = yield call(getUserResultRequest, userId)
    yield put(getUserResultSuccess(result))
  } catch (error) {
    // TODO: handle error
  }
}

export function* getUserResultSaga() {
  yield takeEvery(GET_USER_RESULT, getUserResult)
}

/**
 * User CurrentAvatarData
 */

const getUserCurrentAvatarDataRequest = async (userId, currentAvatarId) => {
  const playground = await getPlaygroundData(userId, currentAvatarId)
  const detail = await avatarDetail(currentAvatarId)
  return {playground, detail}
}

function* getUserCurrentAvatarData({payload}) {
  const {userId, currentAvatarId} = payload

  try {
    if (!currentAvatarId) {
      yield put(getCurrentAvatarDataSuccess(null))
    } else {
      const currentAvatarData = yield call(
        getUserCurrentAvatarDataRequest,
        userId,
        currentAvatarId
      )
      yield put(getCurrentAvatarDataSuccess(currentAvatarData))
    }
  } catch (error) {
    // TODO: handle error
  }
}

export function* getUserCurrentAvatarDataSaga() {
  yield takeEvery(GET_DATA_FOR_CURRENT_AVATAR, getUserCurrentAvatarData)
}

/**
 * Auth Root Saga
 */
export default function* rootSaga() {
  yield all([
    fork(refreshUserFocusPlan),
    fork(getUserResultSaga),
    fork(getUserCurrentAvatarDataSaga),
    fork(refreshUserInfoSaga)
  ])
}
