import React from 'react'
import PropTypes from 'prop-types'

const ErrorMessage = ({message}) =>
  message ? <span style={{color: 'red', fontSize: 12}}>{message}</span> : null

ErrorMessage.defaultProps = {
  message: null
}
ErrorMessage.propTypes = {
  message: PropTypes.string
}
export default ErrorMessage
