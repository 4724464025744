/**
 * Password Actions
 */
import {
  ALL_FOCUS_INSIGTHS,
  ALL_FOCUS_INSIGTHS_SUCCESS,
  ALL_OTHER_INSIGHTS,
  ALL_OTHER_INSIGHTS_SUCCESS,
  ADD_FOCUS_INSIGHT,
  ADD_OTHER_INSIGHT,
  UPDATE_FOCUS_INSIGHT,
  UPDATE_OTHER_INSIGHT
} from './types'

/**
 * Redux Action To Get user insights
 */
export const getFocusInsights = userId => ({
  type: ALL_FOCUS_INSIGTHS,
  payload: {userId}
})
export const getFocusInsightsSuccess = focus => ({
  type: ALL_FOCUS_INSIGTHS_SUCCESS,
  payload: {focus}
})
export const getOtherInsights = userId => ({
  type: ALL_OTHER_INSIGHTS,
  payload: {userId}
})
export const getOtherInsightsSuccess = other => ({
  type: ALL_OTHER_INSIGHTS_SUCCESS,
  payload: {other}
})

/**
 * Redux Action to Add user insight
 */

export const addFocusInsight = record => ({
  type: ADD_FOCUS_INSIGHT,
  payload: {record}
})

export const addOtherInsight = record => ({
  type: ADD_OTHER_INSIGHT,
  payload: {record}
})

export const updateFocusInsight = (record, userId) => ({
  type: UPDATE_FOCUS_INSIGHT,
  payload: {record, userId}
})

export const updateOtherInsight = (record, userId) => ({
  type: UPDATE_OTHER_INSIGHT,
  payload: {record, userId}
})
