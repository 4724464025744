// Signup
export const SIGNUP_USER = 'SIGNUP_USER'
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS'
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE'
export const CONFIRM_USER_SIGNUP = 'CONFIRM_USER_SIGNUP'
export const CONFIRM_USER_SIGNUP_SUCCESS = 'CONFIRM_USER_SIGNUP_SUCCESS'
export const CONFIRM_USER_SIGNUP_FAIL = 'CONFIRM_USER_SIGNUP_FAIL'
export const RESEND_CONFIRMATION = 'RESEND_CONFIRMATION'
export const RESEND_CONFIRMATION_SUCCESS = 'RESEND_CONFIRMATION_SUCCESS'
export const RESEND_CONFIRMATION_FAIL = 'RESEND_CONFIRMATION_FAIL'
