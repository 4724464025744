import React from 'react'
import styled from 'styled-components'
import InfoIcon from '@material-ui/icons/Info'
import Text from 'components/system/Text'
import PTooltip from './PTooltip'

const PasswordHint = () => (
  <PTooltip
    anchor={<InfoIcon fontSize="small" />}
    direction={window.screen.width >= 1024 ? 'top' : 'mobile'}
  >
    <Text textStyle="caps">Password should have :</Text>
    <ul style={{marginBottom: 0, marginTop: 8, padding: 0}}>
      <StyledListItem>- Minimum 8 characters</StyledListItem>
      <StyledListItem>- Maximum 16 characters</StyledListItem>
      <StyledListItem>- At least one upper case character</StyledListItem>
      <StyledListItem>- At least one lower case character</StyledListItem>
    </ul>
  </PTooltip>
)

const StyledListItem = styled.li`
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  margin: 4px 0;
  font-size: 12px;
`

export default PasswordHint
