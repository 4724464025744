import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {GET_COACH_AND_USER, ASSIGN_USER, UNASSIGN_USER} from 'actions/types'
import {getUserList, getCoachList, assignUser, unassignUser} from 'api/coach'
import {getCoachAndUserListSuccess} from 'actions'

const getCoachAndUserRequest = async () => {
  const {data: coachListData} = await getCoachList()
  const {data: userListData} = await getUserList()
  const noOrganization = 'No Organization'
  const noCoach = 'No Coach'

  const coachList = coachListData.reduce((acc, coach) => {
    acc[coach.userid] = coach
    return acc
  }, {})

  const coachAndUserList = userListData
    .filter(user => user.role !== 'manager')
    .reduce((acc, user) => {
      const coachassigned = user.coachassigned
        ? user.coachassigned.length > 8
          ? user.coachassigned
          : noCoach
        : noCoach
      const orgname = user.orgname
        ? user.orgname.length > 0
          ? user.orgname
          : noOrganization
        : noOrganization
      if (!acc[orgname]) {
        acc[orgname] = {}
      }

      const cohort = user.cohort ? user.cohort : 'No Cohort'
      const group = user.group ? user.group : 'No Group'

      if (!acc[orgname][coachassigned]) {
        const coachName =
          coachassigned === noCoach
            ? coachassigned
            : !coachList[coachassigned]
            ? noCoach
            : `${coachList[coachassigned].firstname} ${coachList[coachassigned].lastname}`
        acc[orgname][coachassigned] = {
          coachName,
          cohorts: {}
        }
      }
      if (!acc[orgname][coachassigned]['cohorts'][cohort]) {
        acc[orgname][coachassigned]['cohorts'][cohort] = {}
      }
      if (!acc[orgname][coachassigned]['cohorts'][cohort][group]) {
        acc[orgname][coachassigned]['cohorts'][cohort][group] = {
          users: []
        }
      }
      acc[orgname][coachassigned]['cohorts'][cohort][group].users.push(user)

      return acc
    }, {})

  const userList = userListData.reduce((acc, user) => {
    acc[user.userid] = user
    return acc
  }, {})

  return {coachAndUserList, userList}
}

function* getCoachAndUser() {
  try {
    const result = yield call(getCoachAndUserRequest)
    if (result.message) {
      // TODO: request fail
    } else {
      yield put(getCoachAndUserListSuccess(result))
    }
  } catch (error) {
    // TODO: Request fail
  }
}

function* getCoachAndUserSaga() {
  yield takeEvery(GET_COACH_AND_USER, getCoachAndUser)
}

/**
 * Assign User
 */
const assignUserRequest = async ({coachId, userId}) =>
  assignUser(coachId, userId)
    .then(({data}) => data)
    .catch(err => err)

function* assignUserWithCoach({payload}) {
  try {
    const result = yield call(assignUserRequest, payload)
    if (result.message) {
      // TODO: Request fail
    } else {
      yield put({type: GET_COACH_AND_USER})
    }
  } catch (error) {
    // TODO: Request fail
  }
}

function* assignUserSaga() {
  yield takeEvery(ASSIGN_USER, assignUserWithCoach)
}

/**
 * Unassign User
 */
const unassignUserRequest = async ({userId}) =>
  unassignUser(userId)
    .then(({data}) => data)
    .catch(err => err)

function* unassignUserWithCoach({payload}) {
  try {
    const result = yield call(unassignUserRequest, payload)
    if (result.message) {
      // TODO: Request fail
    } else {
      yield put({type: GET_COACH_AND_USER})
    }
  } catch (error) {
    // TODO: Request fail
  }
}

function* unassignUserSaga() {
  yield takeEvery(UNASSIGN_USER, unassignUserWithCoach)
}

export default function* rootSaga() {
  yield all([
    fork(getCoachAndUserSaga),
    fork(assignUserSaga),
    fork(unassignUserSaga)
  ])
}
