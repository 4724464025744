/**
 * App Redux Action Types
 */
export const COLLAPSED_SIDEBAR = 'COLLAPSED_SIDEBAR'
export const DARK_MODE = 'DARK_MODE'
export const BOXED_LAYOUT = 'BOXED_LAYOUT'
export const RTL_LAYOUT = 'RTL_LAYOUT'
export const MINI_SIDEBAR = 'MINI_SIDEBAR'
export const SEARCH_FORM_ENABLE = 'SEARCH_FORM_ENABLE'
export const CHANGE_THEME_COLOR = 'CHANGE_THEME_COLOR'
export const TOGGLE_SIDEBAR_IMAGE = 'TOGGLE_SIDEBAR_IMAGE'
export const SET_SIDEBAR_IMAGE = 'SET_SIDEBAR_IMAGE'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const START_USER_TOUR = 'START_USER_TOUR'
export const STOP_USER_TOUR = 'STOP_USER_TOUR'
export const TOGGLE_DARK_SIDENAV = 'TOGGLE_DARK_SIDENAV'
