/**
 * Auth User Reducers
 */
import {NotificationManager} from 'react-notifications'
import {
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  CONFIRM_USER_SIGNUP,
  CONFIRM_USER_SIGNUP_SUCCESS,
  CONFIRM_USER_SIGNUP_FAIL,
  RESEND_CONFIRMATION,
  RESEND_CONFIRMATION_SUCCESS,
  RESEND_CONFIRMATION_FAIL
} from 'actions/types'

/**
 * initial sign up user
 */
const INIT_STATE = {
  userInfo: null,
  errorMessage: null
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER:
      return {...state}

    case SIGNUP_USER_SUCCESS:
      return {...state, ...action.payload}

    case SIGNUP_USER_FAILURE:
      NotificationManager.error(action.payload)
      return {...state, errorMessage: action.payload}

    case CONFIRM_USER_SIGNUP:
      return {...state, ...action.playload}

    case CONFIRM_USER_SIGNUP_SUCCESS:
      NotificationManager.success('Account Created')
      return {...state}

    case CONFIRM_USER_SIGNUP_FAIL:
      NotificationManager.error(action.payload)
      return {...state, errorMessage: action.payload}

    case RESEND_CONFIRMATION:
      return {...state}

    case RESEND_CONFIRMATION_SUCCESS:
      NotificationManager.success('Check your email for verification code')
      return {...state}

    case RESEND_CONFIRMATION_FAIL:
      NotificationManager.error(action.payload)

      return {...state, errorMessage: action.payload}

    default:
      return {...state}
  }
}
