import React from 'react'
import Button from '@material-ui/core/Button'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const ErrorPage = ({statusCode, message}) => (
  <Wrapper className="bg-blue" key="1">
    <div className="error-body text-center">
      <h2 className="oops">Oops.. </h2>
      <h2 className="bold mb-0">{statusCode}</h2>
      <h2 className="error-msg mb-30">{message}</h2>
      <Button
        component={Link}
        to="/"
        variant="contained"
        className="btn-light btn-lg"
      >
        Go To Home Page
      </Button>
    </div>
  </Wrapper>
)

ErrorPage.propTypes = {
  statusCode: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired
}

export default ErrorPage

const Wrapper = styled.div`
  z-index: 999;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`
