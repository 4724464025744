/**
 * Rct Page Loader
 */
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from './system/Box'

const RctPageLoader = () => (
  <Box
    display="flex"
    justifyContent="center"
    my={3}
    data-testid="page-loader-container"
    alignItems="center"
    height="100%"
  >
    <CircularProgress data-testid="circular-progress" />
  </Box>
)

export default RctPageLoader
