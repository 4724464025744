/**
 * Auth User Reducers
 */
import {NotificationManager} from 'react-notifications'
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  CHECK_USER_SESSION,
  CHECK_USER_SESSION_SUCCESS,
  CHECK_USER_SESSION_FAIL,
  UPDATE_USER_FOCUSPLAN,
  UPDATE_USER_INFO,
  GET_USER_RESULT_SUCCESS,
  GET_DATA_FOR_CURRENT_AVATAR_SUCCESS,
  SET_REMEMBER_ME,
  UPDATE_CURRENT_AVATAR,
  SET_WORK_EXPERIENCE,
  SET_INTRO_FLAG
} from 'actions/types'

/**
 * initial auth user
 */
const INIT_STATE = {
  userId: localStorage.getItem('user_id') || null,
  // firstLogin: true,
  // firstPlayground: true,
  loading: false,
  result: null,
  currentAvatarData: null,
  rememberMe: localStorage.getItem('rememberMe') === 'true' || false
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHECK_USER_SESSION:
      return {...state, loading: true}

    case CHECK_USER_SESSION_SUCCESS:
      return {...state, loading: false, ...action.payload}

    case CHECK_USER_SESSION_FAIL:
      localStorage.removeItem('user_id')
      localStorage.removeItem('rememberMe')
      localStorage.removeItem('jobKanban')
      localStorage.removeItem('growthKanban')
      return {...state, loading: false, userId: null}

    case LOGIN_USER:
      return {...state, loading: true}

    case LOGIN_USER_SUCCESS:
      return {...state, loading: false, ...action.payload}

    case LOGIN_USER_FAILURE:
      NotificationManager.error(action.payload)
      return {...state, loading: false}

    case LOGOUT_USER:
      return {...state}

    case LOGOUT_USER_SUCCESS:
      localStorage.removeItem('user_id')
      localStorage.removeItem('rememberMe')
      localStorage.removeItem('jobKanban')
      localStorage.removeItem('growthKanban')
      return {...INIT_STATE, userId: null, rememberMe: false}

    case LOGOUT_USER_FAILURE:
      return {...state}

    case UPDATE_USER_FOCUSPLAN:
      return {...state, ...action.payload}

    case UPDATE_USER_INFO:
      return {...state, ...action.payload}

    case GET_USER_RESULT_SUCCESS:
      return {...state, ...action.payload}

    case GET_DATA_FOR_CURRENT_AVATAR_SUCCESS:
      return {...state, ...action.payload}

    case SET_REMEMBER_ME:
      localStorage.setItem('rememberMe', !state.rememberMe)
      return {...state, rememberMe: !state.rememberMe}

    case UPDATE_CURRENT_AVATAR:
      return {...state, currentavatarid: action.payload}

    case SET_WORK_EXPERIENCE:
      return {...state, experienced: action.payload}

    case SET_INTRO_FLAG:
      return {...state, intro: action.payload}

    default:
      return {...state}
  }
}
