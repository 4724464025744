/**
 * AsyncComponent
 * Code Splitting Component / Server Side Rendering
 */
import {lazy} from 'react'

export const AsyncResultGroupComponent = lazy(() =>
  import('routes/result/resultGroup')
)
export const AsyncUserPlaygroundComponent = lazy(() =>
  import('routes/components/UserPlayground')
)
export const AsyncAvatarDetailComponent = lazy(() =>
  import('routes/components/AvatarDetail')
)
export const AsyncAvatarsInFamilyComponent = lazy(() =>
  import('routes/result/resultGroup/userResult/avatarsInFamily')
)
export const AsyncUserHomeComponent = lazy(() => import('routes/home/userHome'))
export const AsyncCoachHomeComponent = lazy(() =>
  import('routes/home/coachHome')
)
export const AsyncCoachUserComponent = lazy(() =>
  import('routes/home/coachHome/coachUser')
)
// export const AsyncUserStatusComponent = lazy(() =>
//   import('routes/home/userStatus')
// )
export const AsyncLandingComponent = lazy(() =>
  import('../container/Landing/index')
)

export const AsyncSurveyComponent = lazy(() => import('components/SurveyForm'))
export const AsyncDashboardComponent = lazy(() =>
  import('../container/Dashboard')
)
export const AsyncPricePlanComponent = lazy(() =>
  import('../container/PricePlan')
)
export const AsyncExperienceComponent = lazy(() =>
  import('../container/Experience')
)
export const AsyncSigninComponent = lazy(() => import('../container/SignIn'))
export const AsyncSignupComponent = lazy(() => import('../container/Signup'))
