import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import AppConfig from 'constants/AppConfig'
import PropTypes from 'prop-types'
import Box from './system/Box'

const AppLoader = ({loadingText}) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    width="100%"
    height="100%"
    data-testid="app-loader"
  >
    <h2
      style={{color: AppConfig.themeColors.primary}}
      className="mb-20"
      data-testid="loading-text"
    >
      {loadingText}
    </h2>
    <CircularProgress data-testid="circular-progress" />
  </Box>
)

AppLoader.defaultProps = {
  loadingText: 'Loading'
}

AppLoader.propTypes = {
  loadingText: PropTypes.string
}

export default AppLoader
