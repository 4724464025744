/**
 * Password Sagas
 */
import {
  getInsight,
  getFocusInsight,
  addFocusInsight,
  addInsight,
  updateFocusInsight,
  updateOtherInsight
} from 'api/coach'
import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {
  ALL_OTHER_INSIGHTS,
  ALL_FOCUS_INSIGTHS,
  ADD_FOCUS_INSIGHT,
  ADD_OTHER_INSIGHT,
  UPDATE_FOCUS_INSIGHT,
  UPDATE_OTHER_INSIGHT
} from 'actions/types'
import {
  getOtherInsightsSuccess,
  getFocusInsightsSuccess,
  getOtherInsights,
  getFocusInsights
} from 'actions'

/**
 * Other Insights
 */
const getOtherInsightsRequest = async userId =>
  getInsight(userId)
    .then(({data}) => data)
    .catch(err => err)

function* getOtherInsightRecords({payload}) {
  try {
    const result = yield call(getOtherInsightsRequest, payload.userId)
    if (result.message) {
      // TODO: yield put(changeUserPasswordFail(result.message))
    } else {
      yield put(getOtherInsightsSuccess(result))
    }
  } catch (error) {
    // TODO: yield put(changeUserPasswordFail(error.message))
  }
}

export function* getAllOtherInsights() {
  yield takeEvery(ALL_OTHER_INSIGHTS, getOtherInsightRecords)
}

/**
 * Focus Insights
 */
const getFocusInsightsRequest = async userId =>
  getFocusInsight(userId)
    .then(({data}) => data)
    .catch(err => err)

function* getFocusInsightRecords({payload}) {
  try {
    const result = yield call(getFocusInsightsRequest, payload.userId)
    if (result.message) {
      // TODO: yield put(changeUserPasswordFail(result.message))
    } else {
      yield put(getFocusInsightsSuccess(result))
    }
  } catch (error) {
    // TODO: yield put(changeUserPasswordFail(error.message))
  }
}

export function* getAllFocusInsights() {
  yield takeEvery(ALL_FOCUS_INSIGTHS, getFocusInsightRecords)
}

/**
 * Add Foucs Insight
 */

const addFocusInsightRequest = async record =>
  addFocusInsight(record)
    .then(({data}) => data)
    .catch(err => err)

function* createFocusInsight({payload}) {
  const {record} = payload
  try {
    const result = yield call(addFocusInsightRequest, record)
    if (result.message) {
      // TODO: yield put(addFocusInsightFail(result.message))
    } else {
      yield put(getFocusInsights(record.userid))
    }
  } catch (error) {
    // TODO: yield put(addFocusInsightFail(error.message))
  }
}

export function* addNewFocusInsight() {
  yield takeEvery(ADD_FOCUS_INSIGHT, createFocusInsight)
}

/**
 * Add Foucs Insight
 */

const addOtherInsightRequest = async record =>
  addInsight(record)
    .then(({data}) => data)
    .catch(err => err)

function* createOtherInsight({payload}) {
  const {record} = payload
  try {
    const result = yield call(addOtherInsightRequest, record)
    if (result.message) {
      // TODO: yield put(addOtherInsightFail(result.message))
    } else {
      yield put(getOtherInsights(record.userid))
    }
  } catch (error) {
    // TODO: yield put(addOtherInsightFail(error.message))
  }
}

export function* addNewOtherInsight() {
  yield takeEvery(ADD_OTHER_INSIGHT, createOtherInsight)
}

/**
 * Update Other Insight
 */
const updateOtherInsightRequest = async record =>
  updateOtherInsight(record)
    .then(({data}) => data)
    .catch(err => err)

function* prepareOtherInsight({payload}) {
  const {record, userId} = payload
  try {
    const result = yield call(updateOtherInsightRequest, record)
    if (result.message) {
      // TODO: yield put(updateOtherInsightFail(result.message))
    } else {
      yield put(getOtherInsights(userId))
    }
  } catch (error) {
    // TODO: yield put(updateOtherInsightFail(error.message))
  }
}

export function* updateOtherInsightSaga() {
  yield takeEvery(UPDATE_OTHER_INSIGHT, prepareOtherInsight)
}
/**
 * Update Focus Insight
 */
const updateFocusInsightRequest = async record =>
  updateFocusInsight(record)
    .then(({data}) => data)
    .catch(err => err)

function* prepareFocusInsight({payload}) {
  const {record, userId} = payload
  try {
    const result = yield call(updateFocusInsightRequest, record)
    if (result.message) {
      // TODO: yield put(updateFocusInsightFail(result.message))
    } else {
      yield put(getFocusInsights(userId))
    }
  } catch (error) {
    // TODO: yield put(updateFocusInsightFail(error.message))
  }
}

export function* updateFocusInsightSaga() {
  yield takeEvery(UPDATE_FOCUS_INSIGHT, prepareFocusInsight)
}

/**
 * Auth Root Saga
 */
export default function* rootSaga() {
  yield all([
    fork(getAllOtherInsights),
    fork(getAllFocusInsights),
    fork(addNewFocusInsight),
    fork(addNewOtherInsight),
    fork(updateOtherInsightSaga),
    fork(updateFocusInsightSaga)
  ])
}
