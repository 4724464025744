import React from 'react'
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {changeUserPassword} from 'actions'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import RouterPropTypes from 'react-router-prop-types'
import PasswordChangeFrom from './PasswordChangeForm'
import {validatePassword} from 'utils/inputValidation'
class PasswordChangeModal extends React.Component {
  state = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    errors: {}
  }

  confirmChange = () => {
    const {oldPassword, newPassword, confirmPassword, errors} = this.state

    errors.oldPassword = validatePassword(oldPassword)
    errors.newPassword = validatePassword(newPassword)
    errors.confirmPassword =
      newPassword !== confirmPassword ? 'Password does not match' : null

    if (errors.oldPassword || errors.newPassword || errors.confirmPassword) {
      this.setState({errors})
    } else {
      // eslint-disable-next-line no-shadow
      const {history, changeUserPassword} = this.props
      changeUserPassword(oldPassword, newPassword, history)
    }
  }

  changeHandler = (key, value) => {
    this.setState({[key]: value})
  }

  render() {
    const {open, closeHandler} = this.props
    const {errors, ...passwords} = this.state
    return (
      <Modal isOpen={open} toggle={closeHandler}>
        <ModalHeader toggle={closeHandler} tag="h3">
          Change Password
        </ModalHeader>
        <ModalBody>
          <PasswordChangeFrom
            errors={errors}
            passwords={passwords}
            changeHandler={this.changeHandler}
          />
        </ModalBody>

        <ModalFooter>
          <div>
            <Button
              variant="contained"
              color="primary"
              className="text-white bg-primary"
              onClick={this.confirmChange}
            >
              Change Password
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

PasswordChangeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  history: RouterPropTypes.history.isRequired,
  changeUserPassword: PropTypes.func.isRequired
}

export default connect(
  null,
  {changeUserPassword}
)(withRouter(PasswordChangeModal))
