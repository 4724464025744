export const REFRESH_USER_FOCUSPLAN = 'REFRESH_USER_FOCUSPLAN'
export const UPDATE_USER_FOCUSPLAN = 'UPDATE_USER_FOCUSPLAN'
export const GET_USER_RESULT = 'GET_USER_RESULT'
export const GET_USER_RESULT_SUCCESS = 'GET_USER_RESULT_SUCCESS'
export const GET_DATA_FOR_CURRENT_AVATAR = 'GET_DATA_FOR_CURRENT_AVATAR'
export const GET_DATA_FOR_CURRENT_AVATAR_SUCCESS =
  'GET_DATA_FOR_CURRENT_AVATAR_SUCCESS'
export const UPDATE_CURRENT_AVATAR = 'UPDATE_CURRENT_AVATAR'
export const SET_WORK_EXPERIENCE = 'SET_WORK_EXPERIENCE'
export const REFRESH_USER_INFO = 'REFRESH_USER_INFO'
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO'
export const SET_INTRO_FLAG = 'SET_INTRO_FLAG'
