import React from 'react'
import {Form, FormGroup, Input, Label} from 'reactstrap'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import ErrorMessage from '../../container/ErrorMessage'

const convertKeyToName = {
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  confirmPassword: 'Confim Password'
}

const PasswordChangeForm = ({changeHandler, errors, message, passwords}) => (
  <Form className="text-left">
    {Object.keys(passwords).map((key, index) => {
      const password = passwords[key]
      return (
        <React.Fragment key={key}>
          <Label style={{fontSize: 14}}>{convertKeyToName[key]}</Label>
          <FormGroup className={`has-wrapper ${index === 2 ? 'mb-0' : ''}`}>
            <Input
              value={password}
              type="Password"
              name={`user-${key}`}
              id={key}
              onChange={event => changeHandler(key, event.target.value)}
            />
            <ErrorMessage message={errors[key]} />

            {message && message !== 'SUCCESS' && key === 'oldPassword' && (
              <ErrorMessage message="Incorrect password" />
            )}
          </FormGroup>
        </React.Fragment>
      )
    })}
  </Form>
)

PasswordChangeForm.defaultProps = {
  errors: {},
  message: null
}

PasswordChangeForm.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    oldPassword: PropTypes.bool,
    newPassword: PropTypes.bool,
    confirmPassword: PropTypes.bool
  }),
  message: PropTypes.string,
  passwords: PropTypes.shape({
    oldPassword: PropTypes.string,
    newPassword: PropTypes.string,
    confirmPassword: PropTypes.string
  }).isRequired
}

const mapStateToProps = ({password}) => {
  const {message} = password
  return {message}
}

export default connect(mapStateToProps)(PasswordChangeForm)
