export default {
  Analytics: {
    // OPTIONAL - disable Analytics if true
    disabled: true,
    // OPTIONAL - Allow recording session events. Default is true.
    autoSessionRecord: true,

    AWSPinpoint: {
      // OPTIONAL -  Amazon Pinpoint App Client ID
      appId: 'b17736ede5fb494faa78949046362c77',
      // OPTIONAL -  Amazon service region
      region: 'us-east-1',
      disabled: false,
      // OPTIONAL - Allow recording session events. Default is true.
      autoSessionRecord: true,
      mandatorySignIn: false
    }
  },
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'ap-southeast-2:28c32b72-c9be-4da8-a3f7-d168ddf031bd',
    // REQUIRED - Amazon Cognito Region
    region: 'ap-southeast-2',

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'ap-southeast-2_Po7MqhwIt',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '5dtb4f7q5bqjv11n8l3mc7ju0n',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Configuration for cookie storage
    // cookieStorage: {
    //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //   domain: 'my.projectme.io',
    //   // OPTIONAL - Cookie path
    //   path: '/',
    //   // OPTIONAL - Cookie expiration in days
    //   expires: 365,
    //   // OPTIONAL - Cookie secure flag
    //   secure: true
    // },

    // OPTIONAL - customized storage object
    // storage: new MyStorage(),

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH'
  },
  Storage: {
    bucket: 'projectme-web-upload',
    region: 'ap-southeast-2'
  },
  aws_appsync_graphqlEndpoint:
    'https://6j4kog55kffzbov44qpzdzpqzu.appsync-api.ap-southeast-2.amazonaws.com/graphql',
  aws_appsync_region: 'ap-southeast-2',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-ozixycxaxvhfhevx6d3iq4dpji'
}
