/**
 * Root Sagas
 */
import {all} from 'redux-saga/effects'
import authSagas from './AuthCognito'
import passwordSagas from './Password'
import signUpSagas from './SignUp'
import insightSagas from './Insight'
import coachSagas from './Coach'
import userSagas from './User'

// eslint-disable-next-line
export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    passwordSagas(),
    signUpSagas(),
    insightSagas(),
    coachSagas(),
    userSagas()
  ])
}
