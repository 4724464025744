import styled from 'styled-components'
import {
  layout,
  flexbox,
  shadow,
  space
  // display,
  // width,
  // height,
  // justifyContent,
  // alignItems,
  // boxShadow,
  // flexDirection
} from 'styled-system'

const Form = styled.form`
  ${space}
  ${layout}
  ${flexbox}
  ${shadow}
`
/* ${width}
  ${height}
  ${boxShadow}
  ${display}
  ${flexDirection}
  ${justifyContent}
  ${alignItems} */

export default Form
