import React, {useState, useCallback} from 'react'
import styled, {css} from 'styled-components'
import {string, func, number, oneOfType} from 'prop-types'
import Text from 'components/system/Text'
import Label from 'components/system/Label'
import Box from 'components/system/Box'
import Input from 'components/system/Input'
import PasswordHint from './PasswordHint'
import theme from '../settings/theme'

const PInput = ({
  placeholder,
  errorMessage,
  focusColor,
  value,
  onChange,
  onBlur,
  type,
  name,
  label,
  autoComplete,
  ...restProps
}) => {
  const [focus, setFocus] = useState(false)
  const setOnFocus = useCallback(() => setFocus(true), [])
  const setOffFocus = useCallback(
    e => {
      setFocus(false)
      if (onBlur) {
        onBlur(e)
      }
    },
    [onBlur]
  )
  return (
    <Box mb={24} {...restProps} style={{flexGrow: 1}} width={1}>
      <LabelText
        mb={1}
        display="inline-block"
        color="neutral.5"
        textStyle="caps"
        focus={focus}
        fontSize={1}
        focusColor={errorMessage ? theme.colors.support.red[3] : focusColor}
        letterSpacing="wide"
        error={errorMessage}
        htmlFor={label}
      >
        <Box display="flex" alignItems="center">
          {label}
          {type === 'password' && <PasswordHint />}
        </Box>
      </LabelText>
      <StyledInput
        id={label}
        display="block"
        type={type}
        name={name}
        py={2}
        px={2}
        fontSize={1}
        placeholder={placeholder}
        borderRadius={2}
        borderColor="neutral.7"
        onFocus={setOnFocus}
        onBlur={setOffFocus}
        focusColor={errorMessage ? theme.colors.support.red[3] : focusColor}
        value={value}
        onChange={onChange}
        error={errorMessage}
        style={{borderWidth: 1}}
        autoComplete={autoComplete}
      />

      {errorMessage && (
        <Text color="support.red.3" textStyle="" fontSize={1}>
          {errorMessage}
        </Text>
      )}
    </Box>
  )
}

PInput.defaultProps = {
  placeholder: '',
  errorMessage: null,
  focusColor: null,
  value: '',
  onChange: null,
  onBlur: null,
  type: 'text',
  name: null,
  label: 'label',
  autoComplete: 'on'
}

PInput.propTypes = {
  placeholder: string,
  errorMessage: string,
  focusColor: string,
  onChange: func,
  onBlur: func,
  value: oneOfType([string, number]),
  type: string,
  name: string,
  label: string,
  autoComplete: string
}

const LabelText = styled(Label)`
  color: ${props => (props.error ? props.focusColor : '')};
  ${props => props.focus && props.focusColor && `color:${props.focusColor}`};
`

const foucsInputEffect = css`
  outline: none;
  caret-color: ${props => props.focusColor};
  &:focus {
    border: 1px solid ${props => props.focusColor};
  }
`

const StyledInput = styled(Input)`
  border-style: solid;
  width: 100%;
  box-sizing: border-box;
  /* Apply focus color effect only when focus color prop is set */
  ${props => props.focusColor && foucsInputEffect}
  border-color: ${props => (props.error ? props.focusColor : '')}
`
export default PInput
