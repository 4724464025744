import {
  CHANGE_USER_PASSWORD,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_FAIL,
  VERIFYING_PASSWORD_RESET,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  FORGOT_USER_PASSWORD
} from 'actions/types'
import {NotificationManager} from 'react-notifications'
/**
 * initial auth user
 */
const INIT_STATE = {
  message: null,
  verifyingReset: false,
  resetSuccess: false
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_USER_PASSWORD:
      return {...state}
    case CHANGE_USER_PASSWORD_SUCCESS:
      NotificationManager.success(
        'Your password has been changed , please login with new password'
      )
      return {...state}
    case CHANGE_USER_PASSWORD_FAIL:
      NotificationManager.error(action.payload)
      return {...state, message: action.payload}
    case FORGOT_USER_PASSWORD:
      return {...INIT_STATE}
    case VERIFYING_PASSWORD_RESET:
      return {...state, verifyingReset: true}
    case RESET_PASSWORD_FAIL:
      NotificationManager.error(action.payload)
      return {...state}
    case RESET_PASSWORD_SUCCESS:
      NotificationManager.success(
        'Your password has been reset , please login with new password'
      )
      return {...state, resetSuccess: true}
    default:
      return {...state}
  }
}
