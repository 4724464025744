// sidebar nav links
import SearchAvatar from './SearchAvatar'

// eslint-disable-next-line no-unused-vars
const getHomeMenu = ({isCoach, isUser, isExperienced}) => {
  const homeMenu = {
    path: '/dashboard/home',
    menu_title: 'sidebar.home',
    menu_icon: 'zmdi zmdi-home'
  }
  const coachChildRoute = {
    menu_title: 'sidebar.coach',
    path: '/dashboard/home/coach'
  }
  const userChildRoute = [
    {
      menu_title: 'sidebar.aboutyou',
      path: '/dashboard/home/aboutyou'
    },
    // isExperienced
    //   ? {
    //       menu_title: 'sidebar.whereiamnow',
    //       path: '/dashboard/home/whereiamnow',
    //     }
    //   : null,
    isCoach ? coachChildRoute : null
  ]

  if (isCoach && !isUser) {
    homeMenu.child_routes = [coachChildRoute]
  } else {
    homeMenu.child_routes = userChildRoute.filter(Boolean)
  }

  return homeMenu
}

const getResultMenu = ({isExperienced}) => {
  const resultMenu = {
    path: '/dashboard/result',
    menu_title: 'sidebar.result',
    menu_icon: 'zmdi zmdi-grid',
    child_routes: [
      {
        component: SearchAvatar
      },
      {
        menu_title: 'sidebar.whatilove',
        path: '/dashboard/result/whatilove'
      }
    ]
  }

  if (isExperienced) {
    const {child_routes} = resultMenu
    resultMenu.child_routes = [
      ...child_routes,
      {
        menu_title: 'sidebar.whatiamgoodat',
        path: '/dashboard/result/whatiamgoodat'
      },
      {
        menu_title: 'sidebar.intersect',
        path: '/dashboard/result/intersect'
      }
    ]
  }

  return resultMenu
}

const getTrackerMenu = () => ({
  path: '/dashboard/tracker',
  menu_title: 'sidebar.tracker',
  menu_icon: 'zmdi zmdi-developer-board',
  child_routes: [
    {
      menu_title: 'sidebar.growth',
      path: '/dashboard/tracker/growth'
    },
    {
      menu_title: 'sidebar.job',
      path: '/dashboard/tracker/job'
    }
  ]
})

const getCalendarMenu = () => ({
  path: '/dashboard/calendar',
  menu_title: 'sidebar.calendar',
  menu_icon: 'zmdi zmdi-calendar'
})

const getMenu = role => [
  getHomeMenu(role),
  getResultMenu(role),
  getTrackerMenu(),
  getCalendarMenu()
]

const defaultRole = {
  isCoach: false,
  isUser: true,
  isExperienced: true
}

const getOnlyCoachMenu = () => {
  const role = {...defaultRole, isCoach: true, isUser: false}
  return [getHomeMenu(role), getCalendarMenu()]
}

const getExperiencedUserMenu = () => {
  const role = {...defaultRole}
  return getMenu(role)
}

export const getLessExperiencedUserMenu = () => {
  const role = {...defaultRole, isExperienced: false}
  return getMenu(role)
}

const getIsUserAndCoachMenu = () => {
  const role = {...defaultRole, isCoach: true}
  return getMenu(role)
}

export default {
  onlyCoach: getOnlyCoachMenu(),
  experiencedUser: getExperiencedUserMenu(),
  lessExperiencedUser: getLessExperiencedUserMenu(),
  userAndCoach: getIsUserAndCoachMenu(),
  manager: [
    {
      path: '/dashboard/home',
      menu_title: 'sidebar.home',
      menu_icon: 'zmdi zmdi-home',
      child_routes: [
        {
          menu_title: 'sidebar.management',
          path: '/dashboard/home/management'
        }
      ]
    }
  ]
}
