import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {object, element, node, oneOfType, arrayOf, func, bool} from 'prop-types'

/** Direct use */
const CustomMuiComponent = ({
  children,
  customCss = {},
  component,
  selectorCss,
  ...restProps
}) => {
  const styles = () => ({
    customCss,
    ...selectorCss
  })

  const CustomizedComponent = ({classes}) => {
    const MuiComponent = component
    const classNames = Object.values(classes).join(' ')
    return (
      <MuiComponent className={classNames} {...restProps}>
        {children}
      </MuiComponent>
    )
  }

  const Component = withStyles(styles)(CustomizedComponent)

  return <Component />
}

CustomMuiComponent.propTypes = {
  children: oneOfType([element, node, arrayOf(element), arrayOf(node)]),
  customCss: object,
  component: oneOfType([
    element,
    node,
    arrayOf(element),
    arrayOf(node),
    object,
    func
  ]),
  selectorCss: oneOfType([object, bool])
}

export default CustomMuiComponent
