import {
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  CONFIRM_USER_SIGNUP,
  CONFIRM_USER_SIGNUP_SUCCESS,
  CONFIRM_USER_SIGNUP_FAIL,
  RESEND_CONFIRMATION,
  RESEND_CONFIRMATION_SUCCESS,
  RESEND_CONFIRMATION_FAIL
} from './types'

/**
 * Redux Action To Signup User In Cognito
 */
export const signUpUserInCognito = (user, history, callback) => ({
  type: SIGNUP_USER,
  payload: {user, history, callback}
})

export const signUpUserInCognitoSuccess = userInfo => ({
  type: SIGNUP_USER_SUCCESS,
  payload: {userInfo}
})

export const signUpUserInCognitoFailure = error => ({
  type: SIGNUP_USER_FAILURE,
  payload: error
})

/**
 * Redux Action To confirm user sign up
 */
export const confirmUserSignUp = (userInfo, code, history, callback) => ({
  type: CONFIRM_USER_SIGNUP,
  payload: {userInfo, code, history, callback}
})

export const confirmUserSignUpSuccess = () => ({
  type: CONFIRM_USER_SIGNUP_SUCCESS
})

export const confirmUserSignUpFail = message => ({
  type: CONFIRM_USER_SIGNUP_FAIL,
  payload: message
})

export const resendConfirmation = username => ({
  type: RESEND_CONFIRMATION,
  payload: {username}
})

export const resendConfirmationSuccess = () => ({
  type: RESEND_CONFIRMATION_SUCCESS
})

export const resendConfirmationFail = message => ({
  type: RESEND_CONFIRMATION_FAIL,
  payload: message
})
