/**
 * Sign up Sagas
 */
import {Auth} from 'aws-amplify'
import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
// import {createAccount, getUserInfoOverall} from 'api'
import {createAccount} from 'api'
import {
  SIGNUP_USER,
  CONFIRM_USER_SIGNUP,
  RESEND_CONFIRMATION
} from 'actions/types'
import {
  signUpUserInCognitoSuccess,
  signUpUserInCognitoFailure,
  confirmUserSignUpSuccess,
  confirmUserSignUpFail,
  resendConfirmationFail,
  resendConfirmationSuccess,
  signinUserSuccess
} from 'actions'
import * as Sentry from '@sentry/browser'

/**
 * Create User
 */

const createUserWithEmailPasswordRequest = async ({
  email,
  password,
  firstName,
  lastName
}) =>
  Auth.signUp({
    username: email,
    password,
    attributes: {
      name: firstName,
      family_name: lastName
    }
  })
    .then(data => data)
    .catch(err => err)

function* createUserWithEmailPassword({payload}) {
  const {user, history, callback} = payload
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, user)
    if (signUpUser.message) {
      callback()
      yield put(signUpUserInCognitoFailure(signUpUser.message))
    } else {
      const {userSub} = signUpUser
      const {username} = signUpUser.user
      const {firstName, lastName} = user

      yield put(
        signUpUserInCognitoSuccess({
          firstName,
          lastName,
          username,
          uid: userSub,
          password: user.password
        })
      )
      history.push('signup/confirmation')
      // Use window open cause data not pass to page
      // window.open('#/signup/confirmation', '_blank')
    }
  } catch (error) {
    Sentry.captureException(error)
    callback()
    yield put(signUpUserInCognitoFailure(error.message))
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword)
}

/**
 * Confirm sign up
 */

const confirmWithUsernameAndCodeRequest = async (username, code) =>
  Auth.confirmSignUp(username, code, {
    // Optional. Force user confirmation irrespective of existing alias. By default set to True.
    forceAliasCreation: true
  })
    .then(data => data)
    .catch(err => err)

function* confirmWithUsernameAndCode({payload}) {
  const {userInfo, code, history, callback} = payload
  const {username, password} = userInfo
  try {
    const result = yield call(confirmWithUsernameAndCodeRequest, username, code)
    if (result.message) {
      callback()
      yield put(confirmUserSignUpFail(result.message))
    } else {
      // Sign user
      const {signInUserSession} = yield call(
        (email, pass) => Auth.signIn(email, pass),
        username,
        password
      )
      // Create user account in database (not cognito)
      const {name, family_name, sub} = signInUserSession.idToken.payload
      let accountInfoResponse
      if (!userInfo.firstName) {
        // if user didn't complete sign up flow first time,try to create user account again
        accountInfoResponse = yield call(createAccount, {
          ...userInfo,
          firstName: name,
          lastName: family_name,
          uid: sub
        })
      } else {
        accountInfoResponse = yield call(createAccount, userInfo)
      }

      // const userInfoResponse = yield call(getUserInfoOverall, username)
      // const {userid, ...rest} = userInfoResponse.data.userInfo[0]
      const {userid, ...rest} = accountInfoResponse.data.userInfo[0]

      yield put(
        signinUserSuccess({
          ...rest,
          // userId: userid,
          userId: sub,
          session: signInUserSession
        })
      )
      localStorage.setItem('user_id', userid)
      yield put(confirmUserSignUpSuccess())
      // Redirect user to purchase page
      history.push('/pricing')
    }
  } catch (error) {
    Sentry.captureException(error)
    callback()
    yield put(signUpUserInCognitoFailure(error.message))
  }
}

export function* confirmUserSignUp() {
  yield takeEvery(CONFIRM_USER_SIGNUP, confirmWithUsernameAndCode)
}

/**
 * Resend confirmation code
 */

const resendSignUp = async username =>
  Auth.resendSignUp(username)
    .then(data => data)
    .catch(err => err)

function* resendCode({payload}) {
  const {username} = payload
  try {
    const result = yield call(resendSignUp, username)
    if (result.message) {
      yield put(resendConfirmationFail(result.message))
    } else {
      yield put(resendConfirmationSuccess())
    }
  } catch (error) {
    Sentry.captureException(error)
    yield put(resendConfirmationFail(error.mesage))
  }
}

export function* resendConfirmationCode() {
  yield takeEvery(RESEND_CONFIRMATION, resendCode)
}

/**
 * Auth Root Saga
 */
export default function* rootSaga() {
  yield all([
    fork(createUserAccount),
    fork(confirmUserSignUp),
    fork(resendConfirmationCode)
  ])
}
