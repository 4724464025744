import Api, {baseURL} from './index'

export const getCoachList = () => Api.get(`${baseURL('2skazmhfgb')}/coach/all`)

export const getUserList = () => Api.get(`${baseURL('2skazmhfgb')}/coach/users`)

export const getFocusPlanForCoach = userid =>
  Api.get(`${baseURL('2skazmhfgb')}/coach/users/${userid}/focusplan`)

// export const getUserSurveyForCoach = userid =>
//   Api.get(`${baseURL('xb530t3byh')}/users/${userid}/registrationinfo`)

export const getUserSurveyForCoach = userid =>
  Api.get(`${baseURL('xb530t3byh')}/users/${userid}/newRegistrationInfo`)
    .then(({data}) => data[0])
    .catch(e => {
      // console.log(e)
      throw e
    })
/**
 * Other Insights
 */
export const getInsight = userid =>
  Api.get(`${baseURL('2skazmhfgb')}/coach/users/${userid}/insights`)

export const addInsight = insight =>
  Api.post(`${baseURL('2skazmhfgb')}/coach/coachinsightjson`, insight)

export const updateOtherInsight = insight =>
  Api.put(`${baseURL('2skazmhfgb')}/coach/coachinsightjson`, insight)

/**
 *  Focus Insights
 */
export const getFocusInsight = userid =>
  Api.get(`${baseURL('2skazmhfgb')}/coach/users/${userid}/focusinsight`)

export const addFocusInsight = insight =>
  Api.post(`${baseURL('2skazmhfgb')}/coachfocusinsight`, insight)

export const updateFocusInsight = insight =>
  Api.put(`${baseURL('2skazmhfgb')}/coachfocusinsight`, insight)

/**
 * Assign User
 */
export const assignUser = (coachId, userId) =>
  Api.put(`${baseURL('2skazmhfgb')}/coach/${coachId}/users/${userId}/assign`)
export const unassignUser = userId =>
  Api.put(`${baseURL('2skazmhfgb')}/coach/users/${userId}/unassign`)
