import React from 'react'
import NameIcons from 'assets/avatarIcons/avatar-name-new-icons.svg' // named
import RandomIcons from 'assets/avatarIcons/avatar-random-icons.svg' // random
import PropTypes from 'prop-types'
// import NameIcons from 'Assets/avatarIcons/avatar-name-icons.svg' // named
// import svgxuse from 'svgxuse' // eslint-disable-line

const avatarArray = [
  'worker',
  'worker-1',
  'worker-2',
  'worker-3',
  'worker-4',
  'worker-5',
  'worker-6',
  'worker-7',
  'worker-8',
  'businessman',
  'businessman-1',
  'woman',
  'woman-1',
  'woman-2',
  'woman-3',
  'woman-4',
  'woman-5',
  'woman-6',
  'woman-7',
  'man',
  'man-1',
  'man-2',
  'man-3',
  'man-4',
  'man-5',
  'man-6',
  'laptop',
  'laptop-1',
  'shop-assistant',
  'desk',
  'best',
  'girl',
  'laptop-2',
  'clock',
  'talk',
  'best-1',
  'employee',
  'effort',
  'goal',
  'teamwork',
  'team',
  'businessman-2',
  'businessman-3',
  'leadership',
  'teacher',
  'young',
  'ginger'
]

const randomName = () => {
  const randomIndex = Math.floor(Math.random() * avatarArray.length)
  return avatarArray[randomIndex]
}

class AvatarIcon extends React.Component {
  static defaultProps = {
    width: 150,
    name: null
  }

  static propTypes = {
    name: PropTypes.string,
    width: PropTypes.number
  }

  constructor(props) {
    super(props)
    const {name} = this.props
    this.state = {
      href: name ? `${NameIcons}#${name}` : `${RandomIcons}#${randomName()}`
    }
  }

  render() {
    const {width} = this.props
    return (
      <div
        style={{
          width,
          height: width
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${width} ${width}`}
          width={width}
          height={width}
        >
          <use xlinkHref={this.state.href} />
        </svg>
      </div>
    )
  }
}

export default AvatarIcon
