/**
 * App Reducers
 */
import {combineReducers} from 'redux'
import settings from './settings'
import sidebarReducer from './SidebarReducer'
import authUserReducer from './AuthUserReducer'
import passwordReducer from './PasswordReducer'
import signUpReducer from './SignUpReducer'
import insightReducer from './InsightReducer'
import coachReducer from './CoachReducer'

const reducers = combineReducers({
  settings,
  sidebar: sidebarReducer,
  authUser: authUserReducer,
  password: passwordReducer,
  signUpUser: signUpReducer,
  insight: insightReducer,
  coach: coachReducer
})

export default reducers
