import React from 'react'
import Box from 'components/system/Box'
import Text from 'components/system/Text'
import ReturnHomeLink from 'components/ReturnHomeLink'
import logo from 'assets/img/logo.png'
import {node, string, oneOfType} from 'prop-types'
import theme from '../settings/theme'

const PSignFormWrapper = ({children, name, footer, pageTitle}) => {
  const [customStyles, setCustomStyles] = React.useState({})

  React.useEffect(() => {
    if (document.body.clientWidth >= 768) {
      setCustomStyles({
        transform: 'translate(-50%,-50%)'
      })
    } else {
      setCustomStyles({
        transform: 'translateX(-50%)'
      })
    }
  }, [])

  return (
    <div className="rct-session-wrapper">
      {pageTitle}
      <ReturnHomeLink />
      <Box
        style={{
          background: `linear-gradient(35deg, ${theme.colors.primary[2]}, ${
            theme.colors.primary[5]
          } , ${theme.colors.primary[6]})`,
          ...customStyles
        }}
        position="absolute"
        left="50%"
        top={{xs: '60px', m: '50%'}}
        display="flex"
        flexDirection={{xs: 'column', lg: 'row'}}
        width={{xs: '95%', m: 520, lg: 800}}
        borderWdith={1}
        borderColor="neutral.3"
        boxShadow={1}
        borderRadius={3}
        overflow="hidden"
      >
        <Box
          width={{xs: '100%', lg: 400}}
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={{xs: 3, m: 4}}
          flexDirection="column"
          style={{flexShrink: 0}}
        >
          <img src={logo} width="100%" alt="logo" />
          <Text
            display={{xs: 'none', lg: 'block'}}
            fontSize={135}
            fontWeight={5}
            color="primary.3"
            my={4}
            ml={4}
            lineHeight={1}
            textStyle="caps"
            opacity={0.45}
          >
            {name}
          </Text>
          {footer && (
            <Text color="primary.8" mt={{xs: 3, m: 4}} lineHeight={1}>
              {footer}
            </Text>
          )}
        </Box>

        <Box
          p={4}
          bg="white"
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{flexGrow: 1}}
        >
          {children}
        </Box>
      </Box>
    </div>
  )
}

PSignFormWrapper.propTypes = {
  children: oneOfType([node, string]),
  name: string,
  footer: oneOfType([node, string]),
  pageTitle: node
}

export default PSignFormWrapper
