import styled from 'styled-components'
import {
  color,
  space,
  border,
  typography,
  shadow,
  layout,
  position

  // borderRadius,
  // boxShadow,
  // fontSize,
  // fontWeight,
  // borderColor,
  // borders,
  // overflow,
  // letterSpacing,
  // display,
  // textAlign,
  // width,
  // height,
  // lineHeight
} from 'styled-system'

const Input = styled.input`
  ${color}
  ${space}
  ${position}
  ${border}
  ${typography}
  ${shadow}
  ${layout}
`
// ${lineHeight}
// ${borderRadius}
// ${boxShadow}
// ${fontSize}
// ${fontWeight}
// ${borders}
// ${borderColor}
// ${overflow}
// ${letterSpacing}
// ${display}
// ${textAlign}
// ${width}
// ${height}

export default Input
