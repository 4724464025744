import React from 'react'
import {withRouter} from 'react-router-dom'
import CustomMuiComponent from 'components/CustomMuiComponent'
import Fab from '@material-ui/core/Fab'
import HomeIcon from '@material-ui/icons/HomeRounded'
import Text from 'components/system/Text'
import {object} from 'prop-types'

const ReturnHomeLink = ({history}) => (
  <CustomMuiComponent
    component={Fab}
    customCss={{
      position: 'absolute',
      right: 15,
      top: 15,
      height: 32,
      zIndex: 1
    }}
    aria-label="Home"
    variant="extended"
    onClick={() => history.push('/')}
    data-testid="home-button"
  >
    <HomeIcon />
    <Text fontSize={{xs: 1, sm: 2}} pl={1}>
      Home
    </Text>
  </CustomMuiComponent>
)

ReturnHomeLink.propTypes = {
  history: object
}

export default withRouter(ReturnHomeLink)
