/* eslint-disable */
import React, {Fragment} from 'react'
import {MuiThemeProvider} from '@material-ui/core/styles'
import {connect} from 'react-redux'
import {IntlProvider} from 'react-intl'
import AppLocale from '../lang'
import muiTheme from '../settings/muiTheme'

const RctThemeProvider = ({locale, children}) => {
  const currentAppLocale = AppLocale[locale.locale]

  return (
    <MuiThemeProvider theme={muiTheme}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Fragment>{children}</Fragment>
      </IntlProvider>
    </MuiThemeProvider>
  )
}

// map state to props
const mapStateToProps = ({settings}) => settings

export default connect(mapStateToProps)(RctThemeProvider)
