import React, {useEffect} from 'react'
import {Provider} from 'react-redux'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import './lib/reactifyCss'
import {ThemeProvider} from 'styled-components'
import App from './container/App'
import {configureStore} from './store'
import theme from './settings/theme'
import RctThemeProvider from './container/RctThemeProvider'

const MainApp = () => {
  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      if (!localStorage.getItem('rememberMe')) {
        localStorage.removeItem('user_id')
      }
    })
  }, [])
  return (
    <Provider store={configureStore()}>
      <ThemeProvider theme={theme}>
        <RctThemeProvider>
          <Router>
            <Switch>
              <Route path="/" component={App} />
            </Switch>
          </Router>
        </RctThemeProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default MainApp
