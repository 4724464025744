const theme = {
  colors: {
    primary: [
      '#10546A',
      '#1C6982',
      '#237490',
      '#3789A4',
      '#489DBA',
      '#6DB8D0',
      '#8FCCE0',
      '#B9E2EE',
      '#D7F1F9',
      '#EBFAFF'
    ],
    neutral: [
      '#1F2933',
      '#323F4B',
      '#3E4C59',
      '#52606D',
      '#615E7C',
      '#7B8794',
      '#9AA5B1',
      '#CBD2D9',
      '#E4E7EB',
      '#F5F7FA'
    ],
    support: {
      red: [
        '#620042',
        '#870557',
        '#A30664',
        '#BC0A6F',
        '#DA127D',
        '#E8368F',
        '#F364A2',
        '#FF8CBA',
        '#FFB8D2',
        '#FFE3EC'
      ],
      yellow: [
        '#8D2B0B',
        '#B44D12',
        '#CB6E17',
        '#DE911D',
        '#F0B429',
        '#F7C948',
        '#FAD85F',
        '#FCE588',
        '#FFF3C4',
        '#FFFBEA'
      ],
      green: [
        '#014D40',
        '#0C6B58',
        '#147D64',
        '#199473',
        '#24AB83',
        '#3EBD93',
        '#65D6AD',
        '#8EEDC7',
        '#C6F7E2',
        '#EFFCF6'
      ]
    }
  },
  fontSizes: [12, 14, 16, 24, 32, 48, 64, 96, 128],
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  letterSpacings: {
    tight: '-0.05em',
    wide: '0.05em',
    ultraWide: '0.125em',
    none: '0em'
  },
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5
  },
  textStyles: {
    caps: {
      textTransform: 'capitalize'
    },
    lower: {
      textTransform: 'lowercase'
    },
    upper: {
      textTransform: 'uppercase'
    },
    none: {
      textTransform: 'none'
    }
  },
  borders: [0, 1, 2, 4, 8, 16, 32],
  borderWidths: [0, 1, 2, 4, 8, 16, 32],
  shadows: [
    '0 1px 3px rgba(0,0,0,0.2)',
    '0 4px 6px rgba(0,0,0,0.2)',
    '0 5px 15px rgba(0,0,0,0.2)',
    '0 10px 24px rgba(0,0,0,0.2)',
    '0 15px 35px rgba(0,0,0,0.2)'
  ],
  radii: [0, 2, 4, 8, 16, '100%', 999]
  // breakpoints: {
  //   xs: 0,
  //   sm: 576,
  //   m: 768,
  //   lg: 992,
  //   xl: 1200,
  //   xxl: 1600
  // }
}

// const breakpoints = [0, 576, 768, 992, 1200, 1600]
const breakpoints = ['0px', '576px', '768px', '992px', '1200px', '1600px']
// const breakpoints = ['576px', '768px', '992px', '1200px', '1600px']
breakpoints.xs = breakpoints[0]
breakpoints.sm = breakpoints[1]
breakpoints.m = breakpoints[2]
breakpoints.lg = breakpoints[3]
breakpoints.xl = breakpoints[4]
breakpoints.xxl = breakpoints[5]

theme.breakpoints = breakpoints

export default theme
