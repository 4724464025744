import React from 'react'
import {Formik} from 'formik'
import Box from 'components/system/Box'
import Form from 'components/system/Form'
import Text from 'components/system/Text'
import {connect} from 'react-redux'
import {validateEmail} from 'utils/inputValidation'
import {forgotUserPassword} from 'actions'
import {Redirect} from 'react-router-dom'
import {bool, func} from 'prop-types'
import PInput from '../../components/PInput'
import theme from '../../settings/theme'
import PSubmitButton from '../../components/PSubmitButton'

const inputValidation = values => {
  // eslint-disable-next-line
  let errors = {}
  const emailError = validateEmail(values.email)
  if (emailError) {
    errors.email = emailError
  }
  return errors
}

// eslint-disable-next-line
const PasswordResetForm = ({verifyingReset, forgotUserPassword}) => {
  if (verifyingReset) {
    return <Redirect to="/password_reset/submit" />
  }

  return (
    <Box display="flex" flexDirection="column" width={1}>
      <Formik
        initialValues={{
          email: ''
        }}
        validate={inputValidation}
        onSubmit={values => {
          forgotUserPassword(values.email)
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched
        }) => (
          <React.Fragment>
            <Text
              fontSize={40}
              fontWeight={5}
              display="block"
              color="neutral.3"
              textStyle="caps"
            >
              forgot your password ?
            </Text>
            <Text fontSize={1} color="neutral.7" mt={2}>
              Enter your e-mail address to reset your password
            </Text>
            <Form
              onSubmit={handleSubmit}
              width={1}
              pt={4}
              display="flex"
              flexDirection="column"
            >
              <PInput
                type="email"
                name="email"
                label="email"
                onChange={handleChange}
                value={values.email}
                errorMessage={errors.email && touched.email && errors.email}
                focusColor={theme.colors.primary[4]}
              />

              <Box display="flex" justifyContent="center" mt={3}>
                <PSubmitButton isSubmitting={isSubmitting} name="reset" />
              </Box>
            </Form>
          </React.Fragment>
        )}
      </Formik>
    </Box>
  )
}

PasswordResetForm.propTypes = {
  verifyingReset: bool,
  forgotUserPassword: func
}

const mapStatesToProps = ({password}) => {
  const {verifyingReset, resetFailMessage} = password
  return {
    verifyingReset,
    resetFailMessage
  }
}

export default connect(
  mapStatesToProps,
  {forgotUserPassword}
)(PasswordResetForm)

// export default PasswordResetForm
