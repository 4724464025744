/* eslint-disable */
/**
 * Sidebar Reducers
 */
import update from 'react-addons-update'
import {
  TOGGLE_MENU,
  TOGGLE_LOVE_INTRO,
  TOGGLE_GOOD_AND_OVERALL_INTRO
} from 'actions/types'
import navLinks from 'container/Dashboard/Sidebar/NavLinks'

const INIT_STATE = {
  sidebarMenus: navLinks,
  loveIntro: false,
  goodAndOverallIntro: false
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_LOVE_INTRO:
      return {...state, loveIntro: true}
    case TOGGLE_GOOD_AND_OVERALL_INTRO:
      return {...state, goodAndOverallIntro: true}
    case TOGGLE_MENU:
      const index = state.sidebarMenus[action.payload.stateCategory].indexOf(
        action.payload.menu
      )
      for (const key in state.sidebarMenus) {
        const obj = state.sidebarMenus[key]
        for (let i = 0; i < obj.length; i += 1) {
          const element = obj[i]
          if (element.open) {
            if (key === action.payload.stateCategory) {
              return update(state, {
                sidebarMenus: {
                  [key]: {
                    [i]: {
                      open: {$set: false}
                    },
                    [index]: {
                      open: {$set: !action.payload.menu.open}
                    }
                  }
                }
              })
            }
            return update(state, {
              sidebarMenus: {
                [key]: {
                  [i]: {
                    open: {$set: false}
                  }
                },
                [action.payload.stateCategory]: {
                  [index]: {
                    open: {$set: !action.payload.menu.open}
                  }
                }
              }
            })
          }
        }
      }
      return update(state, {
        sidebarMenus: {
          [action.payload.stateCategory]: {
            [index]: {
              open: {$set: !action.payload.menu.open}
            }
          }
        }
      })

    default:
      return {...state}
  }
}
