import {
  GET_COACH_AND_USER,
  GET_COACH_AND_USER_SUCCESS,
  ASSIGN_USER,
  UNASSIGN_USER,
  SET_ASSIGNED_USER_INFO
} from './types'

export const getCoachAndUserList = () => ({
  type: GET_COACH_AND_USER
})

export const getCoachAndUserListSuccess = result => ({
  type: GET_COACH_AND_USER_SUCCESS,
  payload: result
})

export const assignUser = (coachId, userId) => ({
  type: ASSIGN_USER,
  payload: {coachId, userId}
})

export const unassignUser = userId => ({
  type: UNASSIGN_USER,
  payload: {userId}
})

export const setAssignedUserinfo = assignedUserInfoList => ({
  type: SET_ASSIGNED_USER_INFO,
  payload: {assignedUserInfoList}
})
