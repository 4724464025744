import {GET_COACH_AND_USER_SUCCESS, SET_ASSIGNED_USER_INFO} from 'actions/types'

const INIT_STATE = {
  coachAndUserList: null,
  assignedUserInfoList: {},
  userList: null
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COACH_AND_USER_SUCCESS:
      return {...state, ...action.payload}
    case SET_ASSIGNED_USER_INFO:
      return {...state, ...action.payload}
    default:
      return {...state}
  }
}
