/**
 * App.js Layout Start Here
 */
import React, {useEffect, Suspense} from 'react'
import {connect} from 'react-redux'
import {Route, Switch} from 'react-router-dom'
import {NotificationContainer} from 'react-notifications'
import Api from 'api'
import {logoutUserFromCognito, checkUserSession} from 'actions'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import {
  AsyncLandingComponent,
  AsyncSurveyComponent,
  AsyncDashboardComponent,
  AsyncPricePlanComponent,
  AsyncExperienceComponent,
  AsyncSigninComponent,
  AsyncSignupComponent
} from 'components/AsyncComponent'
import Loader from 'components/AppLoader'
import * as Sentry from '@sentry/browser'
import PasswordReset from './PasswordReset'
import Navigation from './Navigation'
import NotFound from 'routes/session/404'
import ServerError from 'routes/session/500'

const App = ({
  history,
  authUser,
  logoutUserFromCognito, // eslint-disable-line no-shadow
  checkUserSession, // eslint-disable-line no-shadow
  location
}) => {
  useEffect(() => {
    if (authUser.userId) {
      checkUserSession(history)
    }

    Api.interceptors.response.use(
      response => response,
      error => {
        Sentry.captureException(error)
        if (error.response) {
          if (error.response.status === 401) {
            logoutUserFromCognito(history)
          } else if (error.response.status === 500) {
            history.push('/session/500')
          }
        }
        return error
      }
    )
  }, [authUser.userId, checkUserSession, history, logoutUserFromCognito])

  const renderNavigation = () => {
    // Display nav bar conditionally
    const path = location.pathname
    if (path === '/' || path === '/pricing') {
      return <Navigation dark={path === '/pricing'} />
    }
    return null
  }

  if (authUser.userId && !authUser.session) {
    return <Loader />
  }

  return (
    <Suspense fallback={<Loader />}>
      <NotificationContainer />
      {renderNavigation()}
      <Switch>
        <Route
          exact
          path="/"
          render={props => <AsyncLandingComponent {...props} />}
        />
        <Route
          path="/dashboard"
          render={props => <AsyncDashboardComponent {...props} />}
        />
        <Route
          exact
          path="/signin"
          render={props => <AsyncSigninComponent {...props} />}
        />
        <Route
          exact
          path="/experience"
          render={props => <AsyncExperienceComponent {...props} />}
        />
        <Route
          exact
          path="/pricing"
          render={props => <AsyncPricePlanComponent {...props} />}
        />
        <Route exact path="/register" render={() => <AsyncSurveyComponent />} />
        <Route
          path="/signup"
          render={props => <AsyncSignupComponent {...props} />}
        />
        <Route path="/password_reset" component={PasswordReset} />
        <Route path="/session/500" component={ServerError} />

        <Route component={NotFound} />
      </Switch>
    </Suspense>
  )
}

App.propTypes = {
  logoutUserFromCognito: PropTypes.func.isRequired,
  checkUserSession: PropTypes.func.isRequired,
  authUser: PropTypes.shape({
    userId: PropTypes.string
  }).isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired
}

const mapStateToProps = ({authUser}) => ({authUser})
export default connect(
  mapStateToProps,
  {logoutUserFromCognito, checkUserSession}
)(App)
