import axios from 'axios'
import {Auth} from 'aws-amplify'

const Api = axios.create()

Api.interceptors.request.use(config =>
  Auth.currentSession()
    .then(session => {
      // User is logged in. Set auth header on all requests
      const {jwtToken} = session.idToken

      // eslint-disable-next-line
      config.headers.Authorization = jwtToken
      return Promise.resolve(config)
    })
    .catch(() =>
      // No logged-in user: don't set auth header
      // console.log('session error :', err)
      Promise.resolve(config)
    )
)

export const baseURL = host =>
  `https://${host}.execute-api.ap-southeast-2.amazonaws.com/api/v1`

export const getFamilyAvatar = (userid, familycode, type) =>
  Api.get(
    `${baseURL('g20flfpra6')}/users/${userid}/family/${familycode}/type/${type}`
  )

export const getUserCurrentAvatar = userid =>
  Api.get(`${baseURL('kof8se1jp4')}/users/${userid}/currentrole`)

export const updateFavorite = (userid, avatarid) =>
  Api.post(
    `${baseURL(
      'dghop3xzf6'
    )}/users/${userid}/avatarid/${avatarid}/updatefavorite`
  )

export const updateFocus = (userid, avatarid) =>
  Api.post(
    `${baseURL('dghop3xzf6')}/users/${userid}/avatarid/${avatarid}/updatefocus`
  )

export const addFavorite = (userid, avatarid) =>
  Api.post(
    `${baseURL('dghop3xzf6')}/users/${userid}/avatarid/${avatarid}/addfavorite`
  )

export const removeFavorite = (userid, avatarid) =>
  Api.delete(
    `${baseURL(
      'dghop3xzf6'
    )}/users/${userid}/avatarid/${avatarid}/removefavorite`
  )

export const getUserFocusPlan = userid =>
  Api.get(`${baseURL('hn1pp7sk11')}/users/${userid}/focusplan`)

export const createAccount = ({firstName, lastName, username, uid}) => {
  const data = {
    first: firstName,
    last: lastName,
    email: username,
    uid
  }
  return Api.post(`${baseURL('93r6u4l6ql')}/userprog/addNew_2/`, data)
}

export const getUserInfoOverall = email =>
  Api.get(`${baseURL('93r6u4l6ql')}/userprog/email/${email}/overall`)

// export const getLandingPageData = userid =>
//   Api.get(`${baseURL('xb530t3byh')}/users/${userid}/testLandingPageData`)
//     .then(({data}) => {
//       if (!data) {
//         return null
//       }
//       if (!data[0]) {
//         return null
//       }
//       return data[0]
//     })
//     .catch(e => {
//       throw e
//     })

export const getLandingPageData = userid =>
  Api.get(`${baseURL('xb530t3byh')}/users/${userid}/newlandingpagedata`)
    .then(({data}) => {
      if (!data) {
        return null
      }
      if (!data[0]) {
        return null
      }
      return data[0]
    })
    .catch(e => {
      throw e
    })

export const getPlaygroundData = (userid, avatarid) => {
  return Api.get(
    `${baseURL(
      'xb530t3byh'
    )}/avatarid/${avatarid}/userid/${userid}/testPlayGroundData_v3`
  )
    .then(({data}) => {
      return data ? data[0] : null
    })
    .catch(e => {
      throw e
    })
}

export const avatarDetail = avatarid =>
  Api.get(`${baseURL('xb530t3byh')}/avatarid/${avatarid}/testAllInfoNew`)
    .then(({data}) => {
      return data ? data[0] : null
    })
    .catch(e => {
      throw e
    })

export const allTypeAvatars = userId =>
  Api.get(`${baseURL('g20flfpra6')}/users/${userId}/alltype`)

export const setCurrentAvatar = (userId, avatarId) =>
  Api.post(
    `${baseURL(
      'dghop3xzf6'
    )}/users/${userId}/avatarid/${avatarId}/setcurrentavatar`
  )

export const updateWorkExperience = (email, status) =>
  Api.put(
    `${baseURL(
      '93r6u4l6ql'
    )}/userprog/email/${email}/${status}/updateExperience`
  )
export const subscribeNewsLetter = email =>
  Api.post(`${baseURL('xb530t3byh')}/addToSubList/${email}/Add`)

export const kanbanList = userId =>
  Api.get(`${baseURL('xb530t3byh')}/kanban/${userId}/check`)

export const updateKanbanList = (userid, payload) => {
  const data = {
    userid,
    payload
  }
  Api.put(`${baseURL('xb530t3byh')}/kanban/update`, data)
}

export const userListForSameKanbanItem = kanbanItemId =>
  Api.get(`${baseURL('xb530t3byh')}/kanban/userList/${kanbanItemId}/get`)

// type = tech, task, activity,course
// ID = unique ID
// Methods = GET
export const avatarListForSameKanbanItem = (type, kanbanItemId) =>
  Api.get(
    `${baseURL(
      'xb530t3byh'
    )}/kanban/avatarList/${type.toLowerCase()}/${kanbanItemId}/getAvatars`
  )
    .then(({data}) => data)
    .catch(e => {
      throw e
    })

export const avatarListByUserForSameKanbanItem = (type, kanbanItemId, userId) =>
  Api.get(
    `${baseURL(
      'xb530t3byh'
    )}/kanban/avatarList/${type.toLowerCase()}/${kanbanItemId}/${userId}/getAvatars`
  )
    .then(({data}) => data)
    .catch(e => {
      throw e
    })

export const jobListByAvatarId = avatarId =>
  Api.get(`${baseURL('xb530t3byh')}/jobs/family/${avatarId}/get`)
    .then(({data}) => data)
    .catch(e => {
      throw e
    })
export const jobListByAvatarName = avatarName =>
  Api.get(`${baseURL('xb530t3byh')}/jobs/avatarname/${avatarName}/get`)
    .then(({data}) => data)
    .catch(e => {
      throw e
    })

export const jobKanbanList = userId =>
  Api.get(`${baseURL('xb530t3byh')}/kanbanJob/${userId}/check`)

export const searchCourses = userId =>
  Api.get(`${baseURL('/k15eh6gzz2')}/searchCourses/${userId}/get`)

export const searchCoursesByKeyword = (userId, keywords, id) => {
  // Method - POST
  // JSON BODY
  // “keywords” - elementname
  // “userId” - userid
  // “id” - elementid
  const data = {userId, keywords, id}
  return Api.post(`${baseURL('/k15eh6gzz2')}/searchCourses/keyword/get`, data)
}

export const addCourse = data => {
  // data fields:
  //  “onetid”
  //  “avatar”
  //   “productname”
  //   “description”
  //   “link”
  //   “courseid”

  Api.put(`${baseURL('/k15eh6gzz2')}/addCourse`, data)
}

export const updateJobKanbanList = (userid, payload) => {
  const data = {
    userid,
    payload
  }
  Api.put(`${baseURL('xb530t3byh')}/kanbanjob/update`, data)
}

export const updateIntro = (uid, intro) => {
  // "intro" = flag status
  // "uid" = userid
  // 0 = no intro shown
  // 1 = landing page intro shown
  // 2 = playground intro shown
  const data = {
    uid,
    intro
  }
  return Api.put(`${baseURL('93r6u4l6ql')}/userprog/updateIntro`, data)
}

export const getUsersByCoach = userId =>
  Api.get(`${baseURL('xb530t3byh')}/coach/${userId}/getCoachingGroup`)

export const getRandomAvatar = userId =>
  Api.get(`${baseURL('xb530t3byh')}/user/${userId}/randomAvatar`)

/* Management dashbaord API*/
export const dashboardUserInfo = () =>
  Api.get(`${baseURL('ky93hll6ge')}/dashboard/userinfo`)
export const dashboardUserInterests = () =>
  Api.get(`${baseURL('ky93hll6ge')}/dashboard/userInterests`)
export const dashboardUserStyles = () =>
  Api.get(`${baseURL('ky93hll6ge')}/dashboard/userStyles`)
export const dashboardUserValues = () =>
  Api.get(`${baseURL('ky93hll6ge')}/dashboard/userValues`)
export const dashboardOnBoardProgress = () =>
  Api.get(`${baseURL('ky93hll6ge')}/dashboard/onboardProgress`)
// export const dashboardAll = (team, orgName) => {
//   return Api.post(`${baseURL('ky93hll6ge')}/dashboard/all`, {
//     orgName,
//     team
//   })
// }
export const dashboardAll = (team, orgName) => {
  return Api.post(`${baseURL('ky93hll6ge')}/dashboard/all_test`, {
    orgName,
    team
  })
}

export default Api
