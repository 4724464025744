import React, {useState} from 'react'
import Nav from 'components/system/Nav'
import logo from 'assets/img/logo.png'
import Box from 'components/system/Box'
import Image from 'components/system/Image'
import {connect} from 'react-redux'
import UserBlock from 'container/Dashboard/Header/UserBlock'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import PModal from 'components/PModal'
import Button from '@material-ui/core/Button'
import SigninButton from './SigninButton'

const Navigation = ({dark, authUser}) => {
  const [showVideo, setShowVideo] = useState(false)

  return (
    <Nav
      width={1}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      px={{xs: 2, sm: 3}}
      pt={2}
      bg={dark ? 'primary.4' : ''}
    >
      <Box display="flex" alignItems="center">
        <Link to="/">
          <Box width={{xs: 150, sm: 200, lg: 250, xl: 280}} mr={3}>
            <Image src={logo} alt="logo" />
          </Box>
        </Link>
        {document.body.clientWidth >= 768 && (
          <Button
            variant="outlined"
            size="small"
            onClick={() => setShowVideo(true)}
          >
            Learn more
          </Button>
        )}
      </Box>
      {authUser.userId ? <UserBlock dashboard={false} /> : <SigninButton />}

      <PModal
        heading="Intro"
        open={showVideo}
        toggleHandler={() => setShowVideo(false)}
      >
        <video width="100%" controls>
          <source
            src="https://drive.google.com/uc?export=download&id=1Rt0tb_EJFAz5rCCuYsbEDzJmyawUKZCk"
            type="video/mp4"
          />
          <track default kind="captions" srcLang="en" />
          Your browser does not support HTML5 video.
        </video>
      </PModal>
    </Nav>
  )
}

Navigation.propTypes = {
  dark: PropTypes.bool.isRequired,
  authUser: PropTypes.object.isRequired
}

const mapStatesToProps = ({authUser}) => ({authUser})

export default connect(
  mapStatesToProps,
  null
)(Navigation)
