import 'whatwg-fetch'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import Amplify from 'aws-amplify'
import * as Sentry from '@sentry/browser'
import aws_exports from './Auth/aws_exports' // eslint-disable-line
// import Amplify, {Analytics} from 'aws-amplify'
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://40893a16ae4c4e87a8196f7f379ad88b@sentry.io/1425731',
    ignoreErrors: ['Network Error', 'Custom Object']
  })
}
Amplify.configure(aws_exports)

// window.LOG_LEVEL = 'DEBUG'
// Start monitoring pageview
// Analytics.autoTrack('pageView', {
//   enable: true,
//   eventName: 'pageView',
//   type: 'SPA',
//   provider: 'AWSPinpoint',
//   getUrl: () => window.location.origin + window.location.pathname
// })

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
serviceWorker.register()
