import Api, {baseURL} from './index'

// export const cancelStripeSub = stripeSubId =>
//   Api.post(`${baseURL('2lnnuk2yae')}/cancelSub/${stripeSubId}/cancelTest`)

// export const resumeStripeSub = stripeSubId =>
//   Api.post(`${baseURL('2lnnuk2yae')}/resumeSub/${stripeSubId}/resumeTest`)

// export const customerInfo = customerId =>
//   Api.get(
//     `${baseURL('2lnnuk2yae')}/stripeCustomer/${customerId}/customerInfoTest`,
//   )

// export const getInvoice = customerId =>
//   Api.get(`${baseURL('2lnnuk2yae')}/invoices/${customerId}/getInvoiceTest`)

// export const customersignUp = data =>
//   Api.post(`${baseURL('2lnnuk2yae')}/customerSignupTest`, data)

// export const couponCheck = couponId =>
//   Api.get(`${baseURL('2lnnuk2yae')}/couponCheck/${couponId}/checkTest`)
export const cancelStripeSub = stripeSubId =>
  Api.post(`${baseURL('2lnnuk2yae')}/cancelSub/${stripeSubId}/cancel`)

export const resumeStripeSub = stripeSubId =>
  Api.post(`${baseURL('2lnnuk2yae')}/resumeSub/${stripeSubId}/resume`)

export const customerInfo = customerId =>
  Api.get(`${baseURL('2lnnuk2yae')}/stripeCustomer/${customerId}/customerInfo`)

export const getInvoice = customerId =>
  Api.get(`${baseURL('2lnnuk2yae')}/invoices/${customerId}/getInvoice`)

export const customersignUp = data =>
  Api.post(`${baseURL('2lnnuk2yae')}/customersignup`, data)

export const couponCheck = couponId =>
  Api.get(`${baseURL('2lnnuk2yae')}/couponCheck/${couponId}/check`)

//   '/v1/customerSignupTest'
// '/v1/cancelSub/{subid}/cancelTest'
// '/v1/resumeSub/{subid}/resumeTest'
// '/v1/stripeCustomer/{customerId}/customerInfoTest'
// '/v1/invoices/{customerId}/getInvoiceTest'
// '/v1/couponCheck/{couponId}/checkTest'
