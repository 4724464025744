import styled from 'styled-components'
import {
  typography,
  space,
  textStyle,
  color,
  layout,
  flexbox
  // fontSize,
  // fontWeight,
  // letterSpacing,
  // width,
  // display
} from 'styled-system'

const Label = styled.label`
${color}
${space}
${textStyle}
${typography}
${layout}
${flexbox}
`
// ${display}
// ${fontSize}
// ${fontWeight}
// ${letterSpacing}
// ${width}

export default Label
