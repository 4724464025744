import {
  COLLAPSED_SIDEBAR,
  TOGGLE_MENU,
  TOGGLE_LOVE_INTRO,
  TOGGLE_GOOD_AND_OVERALL_INTRO
} from './types'

export const collapsedSidebarAction = isCollapsed => ({
  type: COLLAPSED_SIDEBAR,
  isCollapsed
})

export const onToggleMenu = selectedMenu => ({
  type: TOGGLE_MENU,
  payload: selectedMenu
})

export const onToggleLoveResult = () => ({
  type: TOGGLE_LOVE_INTRO
})

export const onToggleGoodAndOverallResult = () => ({
  type: TOGGLE_GOOD_AND_OVERALL_INTRO
})
