import styled from 'styled-components'
// import {display, width, position, top, left} from 'styled-system'
import {layout, position} from 'styled-system'

const Image = styled.img`
  ${position};
  ${layout};
`
// ${width};
// ${display};
// ${top};
// ${left};
Image.defaultProps = {
  width: 1
}
export default Image
