import React from 'react'
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Span from '../system/Span'

const PModal = ({
  children,
  footerEnable = true,
  open,
  toggleHandler,
  heading,
  footerText
}) => (
  <Modal isOpen={open} toggle={toggleHandler} size="lg">
    <ModalHeader toggle={toggleHandler} tag="h2" className="text-center">
      {heading}
    </ModalHeader>
    <ModalBody>{children}</ModalBody>
    {footerEnable && (
      <ModalFooter>
        <Span
          color="neutral.6"
          fontSize={2}
          fontWeight={2}
          style={{flexGrow: 1}}
        >
          {footerText}
        </Span>
        <div>
          <Button
            variant="contained"
            className="btn-danger text-white bg-danger"
            onClick={toggleHandler}
          >
            Close
          </Button>
        </div>
      </ModalFooter>
    )}
  </Modal>
)

PModal.defaultProps = {
  footerEnable: true,
  open: false,
  heading: '',
  footerText: null
}

PModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  footerEnable: PropTypes.bool,
  open: PropTypes.bool,
  toggleHandler: PropTypes.func.isRequired,
  heading: PropTypes.string,
  footerText: PropTypes.string
}

export default PModal
