/**
 * Password Actions
 */
import {
  CHANGE_USER_PASSWORD,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_FAIL,
  FORGOT_USER_PASSWORD,
  VERIFYING_PASSWORD_RESET,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  SUBMIT_PASSWORD_RESET
} from './types'

/**
 * Redux Action To Check user session
 */
export const changeUserPassword = (oldPassword, newPassword, history) => ({
  type: CHANGE_USER_PASSWORD,
  payload: {oldPassword, newPassword, history}
})
export const changeUserPasswordFail = message => ({
  type: CHANGE_USER_PASSWORD_FAIL,
  payload: message
})

export const changeUserPasswordSuccess = () => ({
  type: CHANGE_USER_PASSWORD_SUCCESS
})

export const forgotUserPassword = email => ({
  type: FORGOT_USER_PASSWORD,
  payload: {email}
})

export const verifyingPasswordReset = () => ({
  type: VERIFYING_PASSWORD_RESET
})

export const resetPasswordFail = message => ({
  type: RESET_PASSWORD_FAIL,
  payload: message
})

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS
})

export const forgotUserPasswordSubmit = (email, password, code, callback) => ({
  type: SUBMIT_PASSWORD_RESET,
  payload: {email, password, code, callback}
})
