/**
 * Auth Sagas
 */
import {Auth} from 'aws-amplify'
import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {getUserInfoOverall, getLandingPageData} from 'api'
import {LOGIN_USER, LOGOUT_USER, CHECK_USER_SESSION} from 'actions/types'
import {
  signinUserSuccess,
  signinUserFailure,
  checkUserSessionSuccess,
  checkUserSessionFail,
  logoutUserFromCognitoSuccess,
  logoutUserFromCognitoFailure,
  signUpUserInCognitoSuccess
} from 'actions'
import * as Sentry from '@sentry/browser'
// import {getFormLinks, checkForm} from 'utils/checkTypeform'

const isAccessible = (pathname, allowedPath) => {
  const accessibleRoutes = [
    '/',
    '/pricing',
    '/password_reset',
    '/password_reset/submit',
    allowedPath
  ]
  return accessibleRoutes.some(route => route === pathname)
}

const redirectUser = (signInUser, history, fromSignIn = true) => {
  // Determine push route by :
  // 1. Check payment
  // 2. Check work experience
  // 3. Check Survey
  // 4. to /dashboard if above are all done
  const {experienced, surveys, stripe} = signInUser
  const {pathname} = history.location
  if (!stripe || !stripe[0].subValid) {
    if (fromSignIn) {
      history.push('/pricing')
    } else if (!isAccessible(pathname, '/pricing')) {
      history.push('/')
    }
  } else if (experienced === null) {
    if (fromSignIn) {
      history.push('/experience')
    } else if (!isAccessible(pathname, '/experience')) {
      history.push('/')
    }
    // } else if (!checkForm(getFormLinks(experienced), surveys).filled) {
    //   if (fromSignIn) {
    //     history.push('/survey')
    //   } else if (!isAccessible(pathname, '/survey')) {
    //     history.push('/')
    //   }
  } else if (!surveys.regform) {
    if (fromSignIn) {
      history.push('/register')
    } else if (!isAccessible(pathname, '/register')) {
      history.push('/')
      // history.push('/dashboard')
    }
  } else if (fromSignIn) {
    history.push('/dashboard')
  } else if (!isAccessible(pathname) && !/\/dashboard\/.*/.test(pathname)) {
    history.push('/')
  }
}

const createAuthUser = async session => {
  if (session.idToken) {
    const {idToken} = session
    const {payload} = idToken

    try {
      // Get user info
      const {data} = await getUserInfoOverall(payload.email)
      const {userInfo, stripeData} = data
      const {userid, ...rest} = userInfo[0]
      const stripe = stripeData.length ? stripeData : null
      const authUserInfo = {...rest, userId: userid, session, stripe}
      // Get data for dashboard
      const landingPageData = await getLandingPageData(userid)
      if (!landingPageData) {
        Sentry.captureMessage(`userId : "${userid}" has no landing page data`)
        return {
          ...authUserInfo,
          currentavatarid: null
        }
      }
      const {userinfo, ...landingPageDataRest} = landingPageData
      const {currentavatarid} = landingPageDataRest
      // eslint-disable-next-line
      const currentAvatarId = currentavatarid
        ? currentavatarid[0]
          ? currentavatarid[0].currentavatarid
          : null
        : null

      return {
        ...authUserInfo,
        ...userinfo[0],
        info: {...landingPageDataRest},
        currentavatarid: currentAvatarId
      }
    } catch (e) {
      Sentry.captureException(e)
      throw e
    }
  }
  throw new Error('No user session')
}

/**
 * Sigin User With Email and Password Request
 */
const signInUserWithEmailPasswordRequest = async (email, password) => {
  try {
    const {signInUserSession} = await Auth.signIn(email, password)
    return createAuthUser(signInUserSession)
  } catch (e) {
    throw e
  }
}

function* signInUserWithEmailPassword({payload}) {
  const {email, password} = payload.user
  const {history, callback} = payload
  try {
    const signInUser = yield call(
      signInUserWithEmailPasswordRequest,
      email,
      password,
      history
    )
    if (signInUser.message) {
      yield put(signinUserFailure(signInUser.message))
    } else {
      localStorage.setItem('user_id', signInUser.userId)
      yield put(signinUserSuccess(signInUser))
      redirectUser(signInUser, history)
    }
  } catch (error) {
    if (!error.code) {
      Sentry.captureException(error)
    }
    const {code} = error
    if (code === 'UserNotConfirmedException') {
      Auth.resendSignUp(email)
        .then(data => data)
        .catch(e => e)
      yield put(
        signUpUserInCognitoSuccess({
          username: email,
          password
        })
      )
      history.push('signup/confirmation')
    }
    yield put(signinUserFailure(error.message))
    callback()
  }
}

export function* signinUserCognito() {
  yield takeEvery(LOGIN_USER, signInUserWithEmailPassword)
}

/**
 * Check user session
 */

const getSessionAndUserInfo = async () => {
  const session = await Auth.currentSession()
  if (session.message || typeof session === 'string') {
    return Promise.reject(session)
  }
  return createAuthUser(session)
  // try {
  //   const session = await Auth.currentSession()
  //   return Promise.reject({
  //     code: 'NotAuthorizedException',
  //     message: 'Refresh Token has expired',
  //     name: 'NotAuthorizedException'
  //   })
  //   if (session.message || typeof session === 'string') {
  //     return Promise.reject(session)
  //   }
  //   return createAuthUser(session)
  // } catch (e) {
  //   console.log(e)
  //   throw e
  // }
}

function* sessionCheck({payload}) {
  try {
    const authUser = yield call(getSessionAndUserInfo)
    if (authUser.message || typeof authUser === 'string') {
      localStorage.removeItem('user_id')
      yield put(checkUserSessionFail())
    } else {
      yield put(checkUserSessionSuccess(authUser))
      redirectUser(authUser, payload.history, false)
    }
  } catch (error) {
    if (error.code) {
      Sentry.captureMessage(error.message)
    }
    localStorage.removeItem('user_id')
    yield put(checkUserSessionFail())
    yield call(signOutRequest)
    payload.history.push('/')
    // throw error
  }
}

export function* checkUserSession() {
  yield takeEvery(CHECK_USER_SESSION, sessionCheck)
}

/**
 * Signout User from Cognito
 */

const signOutRequest = async () =>
  Auth.signOut()
    .then(data => data)
    .catch(err => err)

function* signOut({payload}) {
  const {history} = payload
  try {
    yield call(signOutRequest)
    yield put(logoutUserFromCognitoSuccess())
    history.push('/')
  } catch (error) {
    Sentry.captureException(error)
    yield put(logoutUserFromCognitoFailure())
  }
}

export function* signOutUser() {
  yield takeEvery(LOGOUT_USER, signOut)
}

/**
 * Auth Root Saga
 */
export default function* rootSaga() {
  yield all([
    fork(signinUserCognito),
    fork(signOutUser),
    fork(checkUserSession)
  ])
}
