/**
 * Auth Actions
 * Auth Action With Google, Facebook
 */
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGIN_FACEBOOK_USER,
  LOGIN_GOOGLE_USER,
  LOGOUT_USER_FAILURE,
  CHECK_USER_SESSION,
  CHECK_USER_SESSION_SUCCESS,
  CHECK_USER_SESSION_FAIL,
  SET_REMEMBER_ME
} from './types'

/**
 * Redux Action To Check user session
 */
export const checkUserSession = history => ({
  type: CHECK_USER_SESSION,
  payload: {history}
})
export const checkUserSessionFail = () => ({
  type: CHECK_USER_SESSION_FAIL
})

export const checkUserSessionSuccess = authUser => ({
  type: CHECK_USER_SESSION_SUCCESS,
  payload: authUser
})

/**
 * Redux Action To Sigin User With Cognito
 */
export const signinUserCognito = (user, history, callback) => ({
  type: LOGIN_USER,
  payload: {user, history, callback}
})

export const signinUserSuccess = user => ({
  type: LOGIN_USER_SUCCESS,
  payload: user
})

export const signinUserFailure = error => ({
  type: LOGIN_USER_FAILURE,
  payload: error
})

/**
 * Redux Action To Signout User From Cognito
 */
export const logoutUserFromCognito = history => ({
  type: LOGOUT_USER,
  payload: {history}
})

export const logoutUserFromCognitoSuccess = () => ({
  type: LOGOUT_USER_SUCCESS
})

export const logoutUserFromCognitoFailure = () => ({
  type: LOGOUT_USER_FAILURE
})

/**
 * Redux Action To Signin User In Firebase With Facebook
 */
export const signinUserWithFacebook = history => ({
  type: LOGIN_FACEBOOK_USER,
  payload: history
})

/**
 * Redux Action To Signin User In Firebase With Google
 */
export const signinUserWithGoogle = history => ({
  type: LOGIN_GOOGLE_USER,
  payload: history
})

export const setRememberMe = () => ({
  type: SET_REMEMBER_ME
})
