import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import PasswordResetFrom from './PasswordResetFrom'
import PasswordResetSubmitForm from './PasswordResetSubmitForm'
import PFormWrapper from '../../components/PSignFormWrapper'

const PageTitle = () => (
  <Helmet>
    <title>ProjectMe | Password</title>
    <meta name="description" content="ProjectMe sign up page" />
  </Helmet>
)

// eslint-disable-next-line
const PasswordReset = ({match}) => (
  <PFormWrapper name="re set" pageTitle={<PageTitle />}>
    <Switch>
      <Route exact path={`${match.url}/`} component={PasswordResetFrom} />
      <Route
        exact
        path={`${match.url}/submit`}
        component={PasswordResetSubmitForm}
      />
    </Switch>
  </PFormWrapper>
)

export default PasswordReset
