import React, {useCallback} from 'react'
import {withRouter} from 'react-router-dom'
import RoutePropTypes from 'react-router-prop-types'
import CustomMuiComponent from 'components/CustomMuiComponent'
import Fab from '@material-ui/core/Fab'
import Text from 'components/system/Text'

const SiginButton = ({history}) => {
  const onUserLogin = useCallback(() => {
    history.push('/signin')
  }, [history])

  return (
    <CustomMuiComponent
      component={Fab}
      customCss={{
        height: 32,
        padding: '0 20px'
      }}
      aria-label="Login"
      variant="extended"
      onClick={onUserLogin}
    >
      <Text fontSize={{xs: 1, sm: 2}} pr={{xs: 0, sm: 1}}>
        Login
      </Text>
    </CustomMuiComponent>
  )
}

SiginButton.propTypes = {
  history: RoutePropTypes.history.isRequired
}

export default withRouter(SiginButton)
