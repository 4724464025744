/**
 * App Light Theme
 */
import {createMuiTheme} from '@material-ui/core/styles'
// import AppConfig from 'Constants/AppConfig'
import theme from './theme'

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      // main: AppConfig.themeColors.primary,
      main: theme.colors.primary[4]
    },
    secondary: {
      // main: AppConfig.themeColors.warning,
      main: theme.colors.support.yellow[4]
    },
    error: {
      main: theme.colors.support.red[4]
    }
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: ['Raleway', '"Helvetica Neue"', 'sans-serif'].join(',')
  }
})

export default muiTheme
