import React, {Component} from 'react'
import {Card, CardBody} from 'reactstrap'
import Input from '@material-ui/core/Input'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import InputAdornment from '@material-ui/core/InputAdornment'
import ButtonBase from '@material-ui/core/ButtonBase'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {connect} from 'react-redux'
import Drawer from '@material-ui/core/Drawer'
import AvatarIcon from 'routes/components/AvatarIcon'
import {Link} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import AppConfig from 'constants/AppConfig'
import avatarFilter from 'utils/avatarFilter'

const convertTitle = {
  good: {title: 'What I Am Good At', path: '/dashboard/result/whatiamgoodat'},
  love: {title: 'What I Love', path: '/dashboard/result/whatilove'},
  overall: {title: 'Intersect', path: '/dashboard/result/intersect'}
}

const styles = () => ({
  cssUnderline: {
    '&:before': {
      borderBottomColor: 'white'
    },
    '&:hover:before': {
      borderBottomColor: 'white !important'
    },
    '&:after': {
      borderBottomColor: AppConfig.themeColors.info
    }
  }
})

class SearchAvatar extends Component {
  static propTypes = {
    result: PropTypes.object,
    classes: PropTypes.object.isRequired
  }

  state = {searchInput: '', openDrawer: false, searchedGroup: {}}

  handleSearch = e => {
    const inputValue = e.target.value
    this.setState({searchInput: inputValue})
    clearTimeout(this.searchTimeout)
    if (inputValue !== '') {
      this.searchTimeout = setTimeout(() => {
        this.applySearch(inputValue)
      }, 600)
    }
  }

  handleClearInput = () => {
    this.setState({searchInput: ''})
  }

  toggleDrawer = () => {
    this.setState(({openDrawer}) => ({openDrawer: !openDrawer}))
  }

  applySearch = searchInput => {
    const group = JSON.parse(JSON.stringify(this.props.result))
    this.setState({
      searchedGroup: avatarFilter(searchInput, group),
      openDrawer: true
    })
  }

  renderInputAdorment = searchInput => (
    <InputAdornment position="end">
      {searchInput === '' ? (
        <SearchIcon color="primary" />
      ) : (
        <ButtonBase onClick={this.handleClearInput}>
          <CloseIcon />
        </ButtonBase>
      )}
    </InputAdornment>
  )

  render() {
    const {searchInput, searchedGroup} = this.state
    const {classes} = this.props
    return (
      <MenuItem>
        <Input
          id="outlined-adornment-password"
          type="text"
          style={{fontSize: 16, width: '100%'}}
          label="Search Avatar"
          placeholder="Search Avatar"
          value={searchInput}
          onChange={e => this.handleSearch(e)}
          endAdornment={this.renderInputAdorment(searchInput)}
          classes={{
            underline: classes.cssUnderline
          }}
          autoComplete="off"
        />
        <Drawer
          anchor="right"
          open={this.state.openDrawer}
          onClose={this.toggleDrawer}
        >
          <div style={{overflow: 'auto'}} className="p-20">
            <h1>
              {`Avatar search results for : `}
              <span style={{color: AppConfig.themeColors.info}}>
                {this.state.searchInput}
              </span>
            </h1>
            {!Object.entries(searchedGroup).length && (
              <h2 className="text-center mt-50 text-secondary">
                - Oops , No Avatars Found -
              </h2>
            )}
            {Object.entries(searchedGroup).map(typeGroup => {
              const [type, familyGroups] = typeGroup
              const {title, path} = convertTitle[type]
              return (
                <Card key={type} className="mb-20">
                  <CardBody>
                    <h2 className="h2">{title}</h2>
                    <div>
                      {Object.entries(familyGroups).map(familyGroup => {
                        const [familyCode, families] = familyGroup
                        const [familyName, avatars] = Object.entries(
                          families
                        )[0]
                        return (
                          <div key={familyCode}>
                            <h4 className="h4 my-5">{familyName}</h4>
                            {avatars.map(avatar => (
                              <div
                                className="d-flex ml-20 align-items-center py-5"
                                key={avatar.oneid}
                              >
                                <AvatarIcon width={20} />
                                <Link
                                  to={`${path}/avatars/${familyCode}/${type}/playground/${avatar.oneid.replace(
                                    /\./gi,
                                    'v'
                                  )}`}
                                  onClick={this.toggleDrawer}
                                >
                                  <StyledAvatarName className="mb-0 h5 ml-10">
                                    {avatar.avatar}
                                  </StyledAvatarName>
                                </Link>
                              </div>
                            ))}
                          </div>
                        )
                      })}
                    </div>
                  </CardBody>
                </Card>
              )
            })}
          </div>
        </Drawer>
      </MenuItem>
    )
  }
}

const mapStateToProps = ({authUser}) => {
  const {result} = authUser
  return {result}
}

export default withStyles(styles)(connect(mapStateToProps)(SearchAvatar))

const MenuItem = styled.div`
  padding: 0.75rem 1.5rem 0.75rem 3.4rem;
`
const StyledAvatarName = styled.span`
  color: ${AppConfig.themeColors.primary};
  position: relative;
  &:hover:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    border-bottom: 1px solid ${AppConfig.themeColors.primary};
  }
`
