import React from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import {bool, string} from 'prop-types'
import theme from '../settings/theme'
import CustomMuiComponent from './CustomMuiComponent'

const PSubmitButton = ({isSubmitting, name}) => (
  <CustomMuiComponent
    component={Button}
    variant="contained"
    type="submit"
    disabled={isSubmitting}
    customCss={{
      width: '100%',
      color: theme.colors.primary[8],
      backgroundColor: theme.colors.primary[4],
      '&:hover': {
        backgroundColor: theme.colors.primary[2]
      },
      flexGrow: 0
    }}
  >
    {isSubmitting ? <CircularProgress size={24} color="inherit" /> : name}
  </CustomMuiComponent>
)

PSubmitButton.defaultProps = {
  name: 'Submit'
}

PSubmitButton.propTypes = {
  isSubmitting: bool.isRequired,
  name: string
}

export default PSubmitButton
