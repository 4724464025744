import styled from 'styled-components'
import {
  color,
  layout,
  flexbox,
  space

  // display,
  // width,
  // height,
  // alignItems,
  // justifyContent,
} from 'styled-system'

const Nav = styled.nav`
  ${color}
  ${space}
  ${layout}
  ${flexbox}
  position:absolute;
  top:0;
  left:0;
  z-index:1;
`

// ${display}
//   ${width}
//   ${height}
//   ${alignItems}
//   ${justifyContent}

export default Nav
