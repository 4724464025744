export const validateEmail = email => {
  if (!email.length) {
    return 'Required'
  }
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    return 'Invalid email address'
  }
  return null
}

export const validatePassword = password => {
  // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,16}$/i
  // const regexOneDigit = /\d/i
  // const regexSpecialChar = /[@$!%*#?&]/
  const regexOneLowercase = /[a-z]/
  const regexOneUppercase = /[A-Z]/

  if (!password.length) {
    return 'Required'
  }

  if (password.length < 8) {
    return 'Password should have minimum 8 characters'
  }
  if (password.length > 16) {
    return 'Password should have maximum 16 characters'
  }

  if (!regexOneLowercase.test(password)) {
    return 'Password should have one lower case character'
  }

  if (!regexOneUppercase.test(password)) {
    return 'Password should have one upper case character'
  }
  // if (!regexOneDigit.test(password)) {
  //   return 'Password should have at least one number'
  // }
  // if (!regexSpecialChar.test(password)) {
  //   return 'Password should have at least one symbol (@$!%*#?&)'
  // }

  // if (!regex.test(password)) {
  //   return 'Password should have minimum eight characters with one uppercase letter , one number , one lowercase letter and one special character(@$!%*#?&)'
  // }
  return null
}
