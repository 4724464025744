import styled from 'styled-components'
import {
  color,
  space,
  typography,
  textStyle,
  layout

  // fontSize,
  // fontWeight,
  // width,
  // letterSpacing,
  // lineHeight,
  // maxWidth,
  // display,
  // textAlign
} from 'styled-system'

const Text = styled.span`
${color}
${space}
${typography}
${layout}
${textStyle}
`
/* ${textAlign}
${display}
${fontSize}
${fontWeight}
${width}
${letterSpacing}
${lineHeight}
${maxWidth} */

export default Text
