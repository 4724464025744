import React from 'react'
import {Formik} from 'formik'
import Box from 'components/system/Box'
import Text from 'components/system/Text'
import {connect} from 'react-redux'
import {validateEmail, validatePassword} from 'utils/inputValidation'
import {forgotUserPasswordSubmit} from 'actions'
import {Redirect} from 'react-router-dom'
import {bool, func} from 'prop-types'
import PInput from '../../components/PInput'
import theme from '../../settings/theme'
import PSubmitButton from '../../components/PSubmitButton'

const inputValidation = values => {
  // eslint-disable-next-line
  let errors = {}
  const {email, password, confirmPassword, code} = values
  const emailError = validateEmail(email)
  const passwordError = validatePassword(password)

  if (emailError) {
    errors.email = emailError
  }

  if (passwordError) {
    errors.password = passwordError
  }

  if (confirmPassword !== password) {
    errors.confirmPassword = 'Confirm password does not match'
  }

  if (!code.length) {
    errors.code = 'Required'
  }
  return errors
}

// eslint-disable-next-line no-shadow
const PasswordResetSubmitForm = ({resetSuccess, forgotUserPasswordSubmit}) => {
  if (resetSuccess) {
    return <Redirect to="/signin" />
  }

  return (
    <Box display="flex" flexDirection="column" width={1}>
      <Formik
        initialValues={{
          email: '',
          password: '',
          confirmPassword: '',
          code: ''
        }}
        validate={inputValidation}
        onSubmit={(values, {setSubmitting}) => {
          const {email, password, code} = values
          forgotUserPasswordSubmit(email, password, code, () =>
            setSubmitting(false)
          )
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched
        }) => (
          <React.Fragment>
            <Text
              fontSize={40}
              fontWeight={5}
              display="block"
              color="neutral.3"
              textStyle="caps"
            >
              reset password
            </Text>
            <Text fontSize={1} color="neutral.7" mt={2}>
              Enter the verification code that was sent to your e-mail address
            </Text>
            <form
              onSubmit={handleSubmit}
              style={{
                width: '100%',
                paddingTop: 36,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <PInput
                name="code"
                label="verification code"
                onChange={handleChange}
                value={values.code}
                errorMessage={errors.code && touched.code && errors.code}
                focusColor={theme.colors.primary[4]}
              />
              <PInput
                type="email"
                name="email"
                label="email"
                onChange={handleChange}
                value={values.email}
                errorMessage={errors.email && touched.email && errors.email}
                focusColor={theme.colors.primary[4]}
              />

              <PInput
                type="password"
                name="password"
                label="new password"
                onChange={handleChange}
                value={values.password}
                errorMessage={
                  errors.password && touched.password && errors.password
                }
                focusColor={theme.colors.primary[4]}
              />
              <PInput
                type="password"
                name="confirmPassword"
                label="confirm password"
                onChange={handleChange}
                value={values.confirmPassword}
                errorMessage={
                  errors.confirmPassword &&
                  touched.confirmPassword &&
                  errors.confirmPassword
                }
                focusColor={theme.colors.primary[4]}
              />

              <Box display="flex" justifyContent="center">
                <PSubmitButton isSubmitting={isSubmitting} name="reset" />
              </Box>
            </form>
          </React.Fragment>
        )}
      </Formik>
    </Box>
  )
}

PasswordResetSubmitForm.propTypes = {
  resetSuccess: bool,
  forgotUserPasswordSubmit: func
}

const mapStatesToProps = ({password}) => {
  const {resetSuccess} = password
  return {
    resetSuccess
  }
}

export default connect(
  mapStatesToProps,
  {forgotUserPasswordSubmit}
)(PasswordResetSubmitForm)

// export default PasswordResetSubmitForm
