import React from 'react'
import ErrorPage from '../ErrorPage'

const ServerNotFound = () => (
  <ErrorPage
    statusCode={500}
    message="Sorry, server goes wrong , please try again later"
  />
)

export default ServerNotFound
