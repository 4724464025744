import React from 'react'
import highlightSearchText from './highlightSearchText'

const avatarFilter = (searchText, group) => {
  const searchedGroup = {}
  Object.entries(group).forEach(d => {
    const [type, familyGroups] = d
    Object.entries(familyGroups).forEach(familyGroup => {
      const [familyCode, family] = familyGroup
      const familyName = Object.keys(family)[0]
      const searchedFamilyCode = {}
      family[familyName].forEach(avatar => {
        const name = highlightSearchText(avatar.avatar, searchText)
        // eslint-disable-next-line
        avatar.avatar = name
        if (React.isValidElement(name)) {
          // Rebuild group
          if (!searchedFamilyCode[familyCode]) {
            searchedFamilyCode[familyCode] = {}
          }
          if (!searchedFamilyCode[familyCode][familyName]) {
            searchedFamilyCode[familyCode][familyName] = []
          }
          searchedFamilyCode[familyCode][familyName].push(avatar)
        }
      })

      if (
        searchedFamilyCode[familyCode] &&
        searchedFamilyCode[familyCode][familyName].length
      ) {
        if (!searchedGroup[type]) {
          searchedGroup[type] = {}
        }
        searchedGroup[type][familyCode] = searchedFamilyCode[familyCode]
      }
    })
  })
  return searchedGroup
}

export default avatarFilter
