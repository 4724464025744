/**
 * App Config File
 */
import logo from 'assets/img/projectme_logo.png'
import sidebarImg from 'assets/img/sidebar-4.jpg'

const AppConfig = {
  appLogo: logo, // App Logo
  brandName: 'ProjectMe', // Brand Name
  navCollapsed: false, // Sidebar collapse
  darkMode: false, // Dark Mode
  boxLayout: false, // Box Layout
  rtlLayout: false, // RTL Layout
  miniSidebar: false, // Mini Sidebar
  enableSidebarBackgroundImage: true, // Enable Sidebar Background Image
  sidebarImage: sidebarImg, // Select sidebar image
  isDarkSidenav: true, // Set true to dark sidebar
  enableThemeOptions: false, // Enable Theme Options
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'en'
  },
  enableUserTour: process.env.NODE_ENV === 'production', // Enable / Disable User Tour
  copyRightText: 'ProjectMe © 2019 All Rights Reserved.', // Copy Right Text
  // light theme colors
  themeColors: {
    // primary: '#5D92F4',
    primary: '#489DBA',
    secondary: '#677080',
    success: '#00D014',
    danger: '#FF3739',
    // warning: '#FFB70F',
    warning: '#FFBD03',
    info: '#00D0BD',
    // dark: '#464D69',
    dark: '#00213C',
    default: '#FAFAFA',
    greyLighten: '#A5A7B2',
    grey: '#677080',
    white: '#FFFFFF',
    purple: '#896BD6',
    yellow: '#D46B08'
  },
  // dark theme colors
  darkThemeColors: {
    darkBgColor: '#424242'
  }
}

export default AppConfig
