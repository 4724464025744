import {
  REFRESH_USER_FOCUSPLAN,
  UPDATE_USER_FOCUSPLAN,
  GET_USER_RESULT,
  GET_USER_RESULT_SUCCESS,
  GET_DATA_FOR_CURRENT_AVATAR,
  GET_DATA_FOR_CURRENT_AVATAR_SUCCESS,
  UPDATE_CURRENT_AVATAR,
  SET_WORK_EXPERIENCE,
  REFRESH_USER_INFO,
  UPDATE_USER_INFO,
  SET_INTRO_FLAG
} from './types'

export const refreshUserInfo = email => ({
  type: REFRESH_USER_INFO,
  payload: {email}
})

export const updateUserInfo = surveys => ({
  type: UPDATE_USER_INFO,
  payload: surveys
})

export const refreshUserFocusPlan = userId => ({
  type: REFRESH_USER_FOCUSPLAN,
  payload: {userId}
})

export const updateUserFocusPlan = info => ({
  type: UPDATE_USER_FOCUSPLAN,
  payload: info
})
export const getUserResult = userId => ({
  type: GET_USER_RESULT,
  payload: {userId}
})
export const getUserResultSuccess = result => ({
  type: GET_USER_RESULT_SUCCESS,
  payload: {result}
})
export const getCurrentAvatarData = (userId, currentAvatarId) => ({
  type: GET_DATA_FOR_CURRENT_AVATAR,
  payload: {userId, currentAvatarId}
})
export const getCurrentAvatarDataSuccess = currentAvatarData => ({
  type: GET_DATA_FOR_CURRENT_AVATAR_SUCCESS,
  payload: {currentAvatarData}
})

export const updateCurrentAvatar = currentAvatarId => ({
  type: UPDATE_CURRENT_AVATAR,
  payload: currentAvatarId
})
export const setWorkExperience = isExperienced => ({
  type: SET_WORK_EXPERIENCE,
  payload: isExperienced
})
export const setIntroFlag = flag => ({
  type: SET_INTRO_FLAG,
  payload: flag
})
