import {
  ALL_FOCUS_INSIGTHS,
  ALL_FOCUS_INSIGTHS_SUCCESS,
  ALL_OTHER_INSIGHTS,
  ALL_OTHER_INSIGHTS_SUCCESS
} from 'actions/types'
// import {NotificationManager} from 'react-notifications'
/**
 * initial auth user
 */
const INIT_STATE = {
  focus: [],
  other: [],
  loading: false
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_FOCUS_INSIGTHS:
      return {...state, loading: true}
    case ALL_FOCUS_INSIGTHS_SUCCESS:
      return {...state, ...action.payload, loading: false}
    case ALL_OTHER_INSIGHTS:
      return {...state, loading: true}
    case ALL_OTHER_INSIGHTS_SUCCESS:
      return {...state, ...action.payload, loading: false}
    default:
      return {...state}
  }
}
