/**
 * Password Sagas
 */
import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {Auth} from 'aws-amplify'
import {
  CHANGE_USER_PASSWORD,
  FORGOT_USER_PASSWORD,
  SUBMIT_PASSWORD_RESET
} from 'actions/types'
import {
  changeUserPasswordSuccess,
  changeUserPasswordFail,
  logoutUserFromCognito,
  verifyingPasswordReset,
  resetPasswordFail,
  resetPasswordSuccess
} from 'actions'

const changePasswordRequest = async ({oldPassword, newPassword}) =>
  Auth.currentAuthenticatedUser()
    .then(user => Auth.changePassword(user, oldPassword, newPassword))
    .then(data => data)
    .catch(err => err)

function* changePassword({payload}) {
  const {history} = payload
  try {
    const result = yield call(changePasswordRequest, payload)
    if (result.message) {
      yield put(changeUserPasswordFail(result.message))
    } else {
      // result === SUCCESS
      yield put(changeUserPasswordSuccess())
      yield put(logoutUserFromCognito(history))
    }
  } catch (error) {
    yield put(changeUserPasswordFail(error.message))
  }
}

export function* changeUserPassword() {
  yield takeEvery(CHANGE_USER_PASSWORD, changePassword)
}

/**
 * Forgot Password
 */

const resetUserPasswordRequest = async email =>
  Auth.forgotPassword(email)
    .then(data => data)
    .catch(err => err)

function* resetUserPassword({payload}) {
  const {email} = payload
  try {
    const result = yield call(resetUserPasswordRequest, email)
    if (result.message) {
      yield put(resetPasswordFail(result.message))
    } else {
      yield put(verifyingPasswordReset())
    }
  } catch (error) {
    yield put(resetPasswordFail(error.message))
  }
}

export function* resetUserPasswordSaga() {
  yield takeEvery(FORGOT_USER_PASSWORD, resetUserPassword)
}

/**
 *
 * Submit password reset
 */
const submitUserPasswordResetRequest = async ({email, password, code}) =>
  Auth.forgotPasswordSubmit(email, code, password)
    .then(data => data)
    .catch(err => err)

function* submitUserPasswordReset({payload}) {
  const {callback} = payload
  try {
    const result = yield call(submitUserPasswordResetRequest, payload)
    if (!result) {
      yield put(resetPasswordSuccess())
    } else {
      const errorMessage =
        result.code === 'ExpiredCodeException'
          ? 'Invalid code or e-mail address provided , please check and try again'
          : result.message
      yield put(resetPasswordFail(errorMessage))
      callback()
    }
  } catch (error) {
    callback()
    yield put(resetPasswordFail(error.message))
  }
}

export function* submitUserPasswordResetSaga() {
  yield takeEvery(SUBMIT_PASSWORD_RESET, submitUserPasswordReset)
}

/**
 * Auth Root Saga
 */
export default function* rootSaga() {
  yield all([
    fork(changeUserPassword),
    fork(resetUserPasswordSaga),
    fork(submitUserPasswordResetSaga)
  ])
}
