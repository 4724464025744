import styled from 'styled-components'
import {
  typography,
  color,
  space

  // letterSpacing,
  // fontSize,
  // fontWeight,
  // lineHeight,
  // textAlign
} from 'styled-system'

const Span = styled.span`
${space}
${color}
${typography}
`
// ${fontSize}
// ${fontWeight}
// ${letterSpacing}
// ${lineHeight}
// ${textAlign}

export default Span
