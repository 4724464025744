import {
  typography,
  layout,
  space,
  textStyle,
  color

  // textAlign,
  // width,
  // fontSize,
  // fontWeight,
  // letterSpacing
} from 'styled-system'
import styled from 'styled-components'

const Table = styled.table`
  ${space};
  ${layout};
`
/* ${width}; */

Table.Head = styled.th`
  ${space};
  ${layout};
  ${color}
  ${typography}
`
/* ${textAlign};
  ${width};
  ${fontSize}
  ${fontWeight} */

Table.Cell = styled.td`
  ${space};
  ${color}
  ${typography}
${textStyle}
`
/* ${textAlign};
  ${fontSize}
  ${fontWeight}
  ${letterSpacing} */

Table.Row = styled.tr``

Table.Body = styled.tbody``

Table.Headers = styled.thead``

Table.Footer = styled.tfoot

export default Table
