import React from 'react'

const highlightSearchText = (name, searchString) => {
  if (searchString !== '') {
    const searchRegex = RegExp(`(${searchString})`, 'gi')
    const result = searchRegex.exec(name)
    if (result) {
      const parts = name.split(searchRegex)
      for (let i = 1; i < parts.length; i += 2) {
        parts[i] = (
          <span
            className="bg-info text-light"
            style={{borderRadius: 10, padding: '0 4px'}}
            key={i}
          >
            {parts[i]}
          </span>
        )
      }
      return <span>{parts}</span>
    }
  }

  return name
}

export default highlightSearchText
