import React, {useState, useCallback} from 'react'
import {instanceOf, element, oneOfType} from 'prop-types'
import Box from 'components/system/Box'
import styled, {css} from 'styled-components'

const PTooltip = ({anchor, children, ...restProps}) => {
  const [show, setShow] = useState(false)
  const toggleTooltip = useCallback(() => setShow(prevShow => !prevShow), [])
  return (
    <Box
      display="flex"
      alignItems="center"
      position="relative"
      onMouseEnter={toggleTooltip}
      onMouseLeave={toggleTooltip}
    >
      {anchor}
      {show && <Tooltip {...restProps}>{children}</Tooltip>}
    </Box>
  )
}

PTooltip.propTypes = {
  anchor: oneOfType([instanceOf(Element), element]).isRequired
}

const Tooltip = ({children, ...restProps}) => (
  <Wrapper {...restProps}>{children}</Wrapper>
)

Tooltip.defaultProps = {
  direction: 'right',
  arrowSize: 6,
  bg: 'white',
  arrowColor: 'rgba(0,0,0,0.65);',
  px: 3,
  py: 2,
  borderRadius: 2
}

const verticalArrow = css`
  left: 50%;
  transform: translateX(-50%);
  border-left: ${props => `${props.arrowSize}px`} solid transparent;
  border-right: ${props => `${props.arrowSize}px`} solid transparent;
`
const horizontalArrow = css`
  top: 50%;
  transform: translateY(-50%);
  border-top: ${props => `${props.arrowSize}px`} solid transparent;
  border-bottom: ${props => `${props.arrowSize}px`} solid transparent;
`
// Position of indicator
const rightArrow = css`
  ${horizontalArrow}
  left: -${props => `${props.arrowSize}px`};
  border-right: ${props => `${props.arrowSize}px`} solid ${props =>
  props.arrowColor};
`
const leftArrow = css`
  ${horizontalArrow}
  right: -${props => `${props.arrowSize}px`};
  border-left: ${props => `${props.arrowSize}px`} solid  ${props =>
  props.arrowColor};
`
const bottomArrow = css`
  ${verticalArrow}
  top: -${props => `${props.arrowSize}px`};
  border-bottom: ${props => `${props.arrowSize}px`} solid  ${props =>
  props.arrowColor};
`
const topArrow = css`
  ${verticalArrow}
  bottom: -${props => `${props.arrowSize}px`};
  border-top: ${props => `${props.arrowSize}px`} solid  ${props =>
  props.arrowColor};
`
const mobileArrow = css`
  left: 40%;
  transform: translateX(-40%);
  border-left: ${props => `${props.arrowSize}px`} solid transparent;
  border-right: ${props => `${props.arrowSize}px`} solid transparent;
  bottom: -${props => `${props.arrowSize}px`};
  border-top: ${props => `${props.arrowSize}px`} solid
    ${props => props.arrowColor};
`

const getIndicator = direction => {
  switch (direction) {
    case 'top':
      return topArrow
    case 'mobile':
      return mobileArrow
    case 'bottom':
      return bottomArrow
    case 'left':
      return leftArrow
    case 'right':
      return rightArrow
    default:
      return leftArrow
  }
}

// Position of tooltip
const offset = 4
const right = css`
  top: 50%;
  left: calc(100% + ${props => `${props.arrowSize + offset}px`});
  transform: translateY(-50%);
`
const left = css`
  top: 50%;
  right: calc(100% + ${props => `${props.arrowSize + offset}px`});
  transform: translateY(-50%);
`
const top = css`
  left: 50%;
  bottom: calc(100% + ${props => `${props.arrowSize + offset}px`});
  transform: translateX(-50%);
`
const mobile = css`
  left: 40%;
  bottom: calc(100% + ${props => `${props.arrowSize + offset}px`});
  transform: translateX(-40%);
`
const bottom = css`
  left: 50%;
  top: calc(100% + ${props => `${props.arrowSize + offset}px`});
  transform: translateX(-50%);
`

const getPosition = direction => {
  switch (direction) {
    case 'top':
      return top
    case 'mobile':
      return mobile
    case 'bottom':
      return bottom
    case 'left':
      return left
    case 'right':
      return right
    default:
      return leftArrow
  }
}

const Wrapper = styled(Box)`
  position: absolute;
  z-index: 999;
  ${props => getPosition(props.direction)} background-color:rgba(0,0,0,0.65);
  color: white;
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    ${props => getIndicator(props.direction)};
  }
`

export default PTooltip
