// Login
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE'
export const LOGIN_FACEBOOK_USER = 'LOGIN_FACEBOOK_USER'
export const LOGIN_GOOGLE_USER = 'LOGIN_GOOGLE_USER'

// Logout
export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE'

// Session
export const CHECK_USER_SESSION = 'CHECK_USER_SESSION'
export const CHECK_USER_SESSION_SUCCESS = 'CHECK_USER_SESSION_SUCCESS'
export const CHECK_USER_SESSION_FAIL = 'CHECK_USER_SESSION_FAIL'

export const SET_REMEMBER_ME = 'SET_REMEMBER_ME'
