/**
 * User Block Component
 */
import React, {useState, useCallback} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {logoutUserFromCognito} from 'actions'
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap'
import PropTypes from 'prop-types'
import RouterPropTypes from 'react-router-prop-types'
import Box from 'components/system/Box'
import PasswordChangeModal from 'components/Password/PasswordChangeModal'
import Subscription from 'container/Subscription'

const UserBlock = ({
  history,
  authUser,
  logoutUserFromCognito, // eslint-disable-line no-shadow
  dashboard
}) => {
  const [userDropdownMenu, setUserDropdownMenu] = useState(false)
  const [openPasswordChange, setOpenPasswordChange] = useState(false)
  const [openSubscription, setOpenSubscription] = useState(false)

  const toggleUserDropdownMenu = useCallback(() => {
    setUserDropdownMenu(prevUserDropdownMenu => !prevUserDropdownMenu)
  }, [])
  const logoutUser = useCallback(() => {
    logoutUserFromCognito(history)
  }, [history, logoutUserFromCognito])

  const renderUserInfo = () => {
    const {firstname, lastname} = authUser
    const userName = firstname ? `${firstname} ${lastname}` : 'User'

    return (
      <Box
        color={dashboard ? 'neutral.3' : 'neutral.9'}
        borderColor={dashboard ? 'neutral.3' : 'neutral.9'}
        borderBottom="1px solid"
        fontSize={{xs: 14, m: 16}}
        letterSpacing="wide"
        className="joyride-step-userblock"
      >
        {userName}
      </Box>
    )
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={userDropdownMenu}
        toggle={toggleUserDropdownMenu}
        style={{cursor: 'pointer'}}
      >
        <DropdownToggle tag="div" className="d-flex align-items-center p-0" nav>
          {authUser && renderUserInfo()}
        </DropdownToggle>
        <DropdownMenu className="py-5 mt-10" right>
          <DropdownItem
            onClick={() => setOpenPasswordChange(true)}
            className="h5"
          >
            Change Password
          </DropdownItem>
          <DropdownItem
            onClick={() => setOpenSubscription(true)}
            className="h5"
          >
            Subscription
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem className="h5" onClick={logoutUser}>
            Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {openPasswordChange && (
        <PasswordChangeModal
          open={openPasswordChange}
          closeHandler={() => setOpenPasswordChange(false)}
        />
      )}

      {openSubscription && (
        <Subscription
          open={openSubscription}
          closeHandler={() => setOpenSubscription(false)}
        />
      )}
    </React.Fragment>
  )
}

UserBlock.defaultProps = {
  dashboard: true
}

UserBlock.propTypes = {
  history: RouterPropTypes.history.isRequired,
  authUser: PropTypes.object.isRequired,
  logoutUserFromCognito: PropTypes.func.isRequired,
  dashboard: PropTypes.bool
}

// map state to props
const mapStateToProps = ({settings, authUser, password}) => {
  const {message} = password
  return {settings, authUser, message}
}

export default connect(
  mapStateToProps,
  {logoutUserFromCognito}
)(withRouter(UserBlock))
