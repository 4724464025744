import styled from 'styled-components'
import {
  typography,
  position,
  color,
  space,
  boxShadow,
  border,
  borderColor,
  background,
  layout,
  flexbox
  // textAlign,
  // display,
  // width,
  // height,
  // justifyContent,
  // alignItems,
  // borders,
  // borderRadius,
  // flexDirection,
  // position,
  // top,
  // left,
  // right,
  // bottom,
  // zIndex,
  // backgroundImage,
  // backgroundSize,
  // flex,
  // overflow,
  // minWidth,
  // maxWidth,
  // maxHeight,
  // minHeight,
  // letterSpacing,
  // fontSize,
  // fontWeight,
  // flexBasis,
  // flexWrap,
} from 'styled-system'

const Box = styled.div`
  ${typography}
  ${position}
  ${color}
  ${space}
  ${boxShadow}
  ${border}
  ${borderColor}
  ${background}
  ${layout}
  ${flexbox}
`
//   ${layout}
// ${flexbox}
// ${flexWrap}
// ${flexBasis}
// ${fontWeight}
// ${fontSize}
// ${textAlign}
// ${space}
// ${width}
// ${height}
// ${borders}
// ${color}
// ${boxShadow}
// ${display}
// ${flexDirection}
// ${justifyContent}
// ${borderRadius}
// ${alignItems}
// ${borderColor}
// ${position}
// ${top}
// ${left}
// ${zIndex}
// ${bottom}
// ${backgroundImage}
//   ${backgroundSize}
//   ${flex}
//   ${overflow}
//   ${right}
//   ${maxWidth}
//   ${minWidth}
//   ${maxHeight}
//   ${minHeight}
//   ${letterSpacing}

export default Box
